import React from 'react'

const funFact = [
  {
      title: '30+',
      subTitle: 'Room & Suites',
  },
//   {
//       title: '1',
//       subTitle: 'Restaurant',
//   },
  {
      title: '50+',
      subTitle: 'Exceptional Food Items',
  },
  {
    title: '10+',
    subTitle: 'Add-ons',
},
  {
      title: '10+',
      subTitle: 'Destinations',
  },


]


const FunFact = (props) => {
    return(
      <section className={`wpo-fun-fact-section ${props.fClass}`}>
          <div className="container-fluid">
              <div className="row">
                  <div className="col col-xs-12">
                      <div className="wpo-fun-fact-grids clearfix">
                          {funFact.map((funfact, fitem) => (
                                <div className="grid" key={fitem} style={{display:'flex',justifyContent:'center'}}>
                                    <div className="info">
                                        <h3>{funfact.title}</h3>
                                        <p>{funfact.subTitle}</p>
                                    </div>
                                </div>
                            ))}
                      </div>
                  </div>
              </div>
          </div>
      </section>
    )
}

export default FunFact;