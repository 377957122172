import React, {useState,useEffect,Fragment} from 'react';
import Grid from "@mui/material/Grid";
import SimpleReactValidator from "simple-react-validator";
import {toast} from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import Sidebar from '../Sidebar/Sidebar.jsx';
import Logo from '../../images/logo.png'
import Scrollbar from '../../components/scrollbar'
import './style.scss';
import './style1.css';

const DashboardPage = (props) => {
    const [loading, setLoading] = useState(false);
    // const submitForm = async () => {
    //     var bodyformdata = new FormData();
    //     bodyformdata.append("reg_id",5547)
    //     bodyformdata.append("bs1_id1",5330)
    //             try {
    //                 setLoading(true);
    //                 const res = await axios({
    //                         method: "post",
    //                         url:'https://www.asiatech.in/booking_engine/admin/ajaxrequest/index2.php',
    //                         data: bodyformdata,
    //                         headers: {
    //                                 "ngrok-skip-browser-warning": "69420"
    //                         },
    //                 });
    //                 console.log(res)
    //                 console.log(res.data)
    //                 } catch (error) {
    //                     console.log(error);
    //                     toast.error("Error occurred while signing in. Please try again.");
    //                 } finally {
    //                     setLoading(false);
    //                 }
    // };
    // useEffect(() => {
    //     submitForm()
    // }, []); 
    // useEffect(() => {
    //     const showNavbar = (toggleId, navId, bodyId, headerId) => {
    //       const toggle = document.getElementById(toggleId),
    //         nav = document.getElementById(navId),
    //         bodypd = document.getElementById(bodyId),
    //         headerpd = document.getElementById(headerId);
    
    //       // Validate that all variables exist
    //       if (toggle && nav && bodypd && headerpd) {
    //         toggle.addEventListener('click', () => {
    //           // show navbar
    //           nav.classList.toggle('show');
    //           // change icon
    //           toggle.classList.toggle('bx-x');
    //           // add padding to body
    //           bodypd.classList.toggle('body-pd');
    //           // add padding to header
    //           headerpd.classList.toggle('body-pd');
    //         });
    //       }
    //     };
    
    //     showNavbar('header-toggle', 'nav-bar', 'body-pd', 'header');
    
    //     /*===== LINK ACTIVE =====*/
    //     const linkColor = document.querySelectorAll('.nav_link');
    
    //     function colorLink() {
    //       if (linkColor) {
    //         linkColor.forEach(l => l.classList.remove('active'));
    //         this.classList.add('active');
    //       }
    //     }
    
    //     linkColor.forEach(l => l.addEventListener('click', colorLink));
    
    //   }, []); 
    const push = useNavigate()

    const [value, setValue] = useState({
        age: '',
        phone_no: '',
        email: '',
        full_name: '',
        password: '',
        confirm_password: '',
    });
   
    const changeHandler = (e) => {
        setValue({...value, [e.target.name]: e.target.value});
        validator.showMessages();
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    return (
        <Fragment>
        {/* <Navbar hclass={'wpo-header-style-3'} Logo={Logo} /> */}
        <div
      style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}
    >
        <Sidebar />
      {/* <CDBSidebar textColor="#fff" backgroundColor="#333" style={{width:'17%'}}> */}
      
        <Grid className="loginWrapper" style={{width:'83%'}}>

            <Grid className="loginForm">
                <h2>Dashboard</h2>
                {/* <p>Signup your account</p> */}
                {/* <form onSubmit={submitForm}> */}
                    <Grid container justifyContent={'center'} spacing={0}>
                        <img src={Logo} alt='Logo' />
                        {/* <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                disabled
                                placeholder="Full Name"
                                value={value.full_name}
                                variant="outlined"
                                name="full_name"
                                label="Full Name"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="formFooter">
                                <Button fullWidth className="cBtn cBtnLarge cBtnTheme" type="submit">Update</Button>
                            </Grid>
                        </Grid> */}
                    </Grid>
                {/* </form> */}
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>
    </div>
        {/* <Footer/>  */}
            <Scrollbar/>
        </Fragment>
    )
};

export default DashboardPage;
// import React, { useState, useEffect, Fragment } from 'react';
// import Grid from "@mui/material/Grid";
// import SimpleReactValidator from "simple-react-validator";
// import { toast } from "react-toastify";
// import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
// import { Link, useNavigate } from "react-router-dom";

// import Logo from '../../images/logo.png';
// import Scrollbar from '../../components/scrollbar';
// import Footer from '../../components/footer';
// import Navbar from '../../components/Navbar';
// import './style.scss';
// import './style1.css';

// const DashboardPage = (props) => {
//     const [loading, setLoading] = useState(false);
//     const push = useNavigate();
//     useEffect(() => {
//         /*===== LINK ACTIVE =====*/
//         const linkColor = document.querySelectorAll('.nav_link');
    
//         function colorLink() {
//           if (linkColor) {
//             linkColor.forEach(l => l.classList.remove('active'));
//             this.classList.add('active');
//           }
//         }
    
//         linkColor.forEach(l => l.addEventListener('click', colorLink));
    
//       }, []); 
//     const [value, setValue] = useState({
//         age: '',
//         phone_no: '',
//         email: '',
//         full_name: '',
//         password: '',
//         confirm_password: '',
//     });

//     const [isNavbarVisible, setNavbarVisible] = useState(false);

//     const changeHandler = (e) => {
//         setValue({ ...value, [e.target.name]: e.target.value });
//         validator.showMessages();
//     };

//     const [validator] = React.useState(new SimpleReactValidator({
//         className: 'errorMessage'
//     }));

//     const submitForm = async (e) => {
//         e.preventDefault();
//         // ... your existing submitForm logic ...
//     };

//     const toggleNavbar = () => {
//         // Update React state to toggle navbar visibility
//         setNavbarVisible(!isNavbarVisible);
//     };

//     useEffect(() => {
//         // Attach event listener to the toggle button
//         const toggle = document.getElementById('header-toggle');
//         if (toggle) {
//             toggle.addEventListener('click', toggleNavbar);
//         }

//         // Cleanup the event listener when the component unmounts
//         return () => {
//             if (toggle) {
//                 toggle.removeEventListener('click', toggleNavbar);
//             }
//         };
//     }, [isNavbarVisible]); // Dependency array includes isNavbarVisible

//     return (
//         <Fragment>
//             {/* <Navbar hclass={'wpo-header-style-3'} Logo={Logo} /> */}
//             <div className="bboddy">
                
//             <header className="header" id="header">
//                 <div className="header_toggle"> <i className={`bx ${isNavbarVisible ? 'bx-x' : 'bx-menu'}`} id="header-toggle"></i> </div>
//                 <div className="header_img"> <img src="https://i.imgur.com/hczKIze.jpg" alt="" /> </div>
//             </header>
//             <div className={`l-navbar ${isNavbarVisible ? 'show' : ''}`} id="nav-bar">
//                 <nav className="nav">
//              <div> <a href="#" className="nav_logo"> <i className='bx bx-layer nav_logo-icon'></i> <span className="nav_logo-name">BBBootstrap</span> </a>
//                 <div className="nav_list"> <a href="#" className="nav_link active"> <i className='bx bx-grid-alt nav_icon'></i> <span className="nav_name">Dashboard</span> </a> <a href="#" className="nav_link"> <i className='bx bx-user nav_icon'></i> <span className="nav_name">Users</span> </a> <a href="#" className="nav_link"> <i className='bx bx-message-square-detail nav_icon'></i> <span className="nav_name">Messages</span> </a> <a href="#" className="nav_link"> <i className='bx bx-bookmark nav_icon'></i> <span className="nav_name">Bookmark</span> </a> <a href="#" className="nav_link"> <i className='bx bx-folder nav_icon'></i> <span className="nav_name">Files</span> </a> <a href="#" className="nav_link"> <i className='bx bx-bar-chart-alt-2 nav_icon'></i> <span className="nav_name">Stats</span> </a> </div>
//            </div> <a href="#" className="nav_link"> <i className='bx bx-log-out nav_icon'></i> <span className="nav_name">SignOut</span> </a>
//                 </nav>
//             </div>
            
//             <Grid className="loginWrapper">
//                 <Grid className="loginForm">
//                     <h2>Dashboard</h2>
//                     <form onSubmit={submitForm}>
//                         <Grid container spacing={3}>
//                             <Grid item xs={12}>
//                                 <TextField
//                                     className="inputOutline"
//                                     fullWidth
//                                     disabled
//                                     placeholder="Full Name"
//                                     value={value.full_name}
//                                     variant="outlined"
//                                     name="full_name"
//                                     label="Full Name"
//                                     InputLabelProps={{
//                                         shrink: true,
//                                     }}
//                                     onBlur={(e) => changeHandler(e)}
//                                     onChange={(e) => changeHandler(e)}
//                                 />
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <Grid className="formFooter">
//                                     <Button fullWidth className="cBtn cBtnLarge cBtnTheme" type="submit">Update</Button>
//                                 </Grid>
//                             </Grid>
//                         </Grid>
//                     </form>
//                     <div className="shape-img">
//                         <i className="fi flaticon-honeycomb"></i>
//                     </div>
//                 </Grid>
//             </Grid>
            
//             </div>
//             <Footer />
//             <Scrollbar />
//         </Fragment>
//     )
// };

// export default DashboardPage;
