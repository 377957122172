import React from 'react'
import Services from '../../api/service'
import { Link } from 'react-router-dom'


const ServiceSection2 = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <div className="wpo-service-area section-padding">
            <div className="wpo-service-wrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-4 col-lg-8 col-12">
                            <div className="wpo-service-content">
                                <h2>We Provide the Best Facilities</h2>
                                <p>Experience excellence with our top-notch facilities, designed to elevate your comfort and delight throughout your stay. </p>
                                <a className="theme-btn" href="/amenities">Discover More</a>
                            </div>
                        </div>
                        <div className="col-xl-8 col-12">
                            <div className="wpo-service-items">
                                {Services.map((service, sitem) => (
                                    <div className="wpo-service-item" key={sitem} >
                                        <Link
                                            onClick={ClickHandler}
                                            to={`/service-single/${service.id}`}
                                            className="service-content"
                                            style={(service.padding) ? { paddingTop: '16px' } : null}
                                        >
                                        <i className={`fi ${service.fIcon}`}></i>
                                        <div>{service.title}</div>
                                        </Link>
                                        {/* <i className={`fi ${service.fIcon}`}></i>
                                        <Link onClick={ClickHandler} to={`/service-single/${service.id}`} style={(service.padding) ? { paddingTop: '18px' } : null}>{service.title}</Link> */}
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceSection2;