import React from 'react'
import { Link } from 'react-router-dom'

const PageTitle = (props) => {
    return (
        // <div className="wpo-breadcumb-area" style={{background:`url(${props.bg})`}}>
        //     <div className="container">
        //         <div className="row">
        //             <div className="col-12">
        //                 <div className="wpo-breadcumb-wrap">
        //                     <h2>{props.pageTitle}</h2>
        //                     <ul>
        //                         <li><Link to="/home">Home</Link></li>
        //                         <li><span>{props.pagesub}</span></li>
        //                     </ul>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div style={{paddingTop: '15px',paddingBottom:'15px',display: 'flex', justifyContent:'center', alignItems: 'center',background:'rgba(120 185 217)'}}>
            {/* <div style={{paddingTop: '15px',paddingBottom:'15px',display: 'flex', justifyContent:'center', alignItems: 'center',background:'rgba(13, 59, 93, 0.7)'}}> */}
            <h2 style={{color:'white',fontSize:'36px',lineHeight:'30px'}}>{props.pageTitle} </h2>
            </div>
    )
}

export default PageTitle;