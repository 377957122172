import React, {useState,Fragment} from 'react';
import Grid from "@mui/material/Grid";
import SimpleReactValidator from "simple-react-validator";
import {toast} from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";

import Logo from '../../images/logo.png'
import Scrollbar from '../../components/scrollbar'
import Footer from '../../components/footer';
import Navbar from '../../components/Navbar'
import './style.scss';

const SignUpPage = (props) => {

    const [loading, setLoading] = useState(false);
    const push = useNavigate()

    const [value, setValue] = useState({
        age: '',
        phone_no: '',
        email: '',
        full_name: '',
        password: '',
        confirm_password: '',
    });

    const changeHandler = (e) => {
        setValue({...value, [e.target.name]: e.target.value});
        validator.showMessages();
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));


    const submitForm = async (e) => {
        e.preventDefault();
        const email = value.email;
        const password = value.password;
        const name = value.full_name;
        const phone_no=value.phone_no;
        const age=Number(value.age);
        const role="user"
        const created_by="user"
        if (value.password !== value.confirm_password)
        {
            toast.error('The confirmed password must match the entered password!');
        }
        else if (validator.allValid()) {
            try {
                setLoading(true);
                const res = await axios.post(
                        `${process.env.REACT_APP_BASE_URL}/api/user`,
                        {
                            name,
                            age,
                            email,
                            password,
                            phone_no,
                            role,
                            created_by
                        }
                    );
                    if (res.data.success) {
                        if(res.data.result==="Email already registered")
                        {
                            toast('Email already registered');
                        }
                        else{
                            toast.success('Registration Complete successfully!');
                        }
                    }else {
                      console.log("Error Occurred");
                      toast.error(res.data.message);
                    }
                } catch (error) {
                    console.log(error);
                    toast.error(
                        "Error occurred while signing in. Please try again."
                    );
                } finally {
                    setLoading(false);
                    setValue({
                        age: '',
                        phone_no: '',
                        email: '',
                        full_name: '',
                        password: '',
                        confirm_password: '',
                    });
                    validator.hideMessages();
                    push('/login');
                }
        } else {
            validator.showMessages();
            toast.error('Empty field is not allowed!');
        }
    };
    return (
        <Fragment>
        <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
        <Grid className="loginWrapper">

            <Grid className="loginForm">
                <h2>Signup</h2>
                <p>Signup your account</p>
                <form onSubmit={submitForm}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Full Name"
                                value={value.full_name}
                                variant="outlined"
                                name="full_name"
                                label="Name"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('full name', value.full_name, 'required')}
                            {/* {validator.message('full name', value.full_name, 'required|alpha')} */}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Age"
                                value={value.age}
                                variant="outlined"
                                name="age"
                                label="Age"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('age', value.age, 'required|numeric')}
                            {/* {validator.message('full name', value.full_name, 'required|alpha')} */}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Phone Number"
                                value={value.phone_no}
                                variant="outlined"
                                name="phone_no"
                                label="Phone Number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('Phone No', value.phone_no, 'required|numeric')}
                            {/* {validator.message('full name', value.full_name, 'required|alpha')} */}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="E-mail"
                                value={value.email}
                                variant="outlined"
                                name="email"
                                label="E-mail"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('email', value.email, 'required|email')}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Password"
                                type='password'
                                value={value.password}
                                variant="outlined"
                                name="password"
                                label="Password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('password', value.password, 'required')}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Confirm Password"
                                value={value.confirm_password}
                                variant="outlined"
                                type='password'
                                name="confirm_password"
                                label="Confirm Password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {/* {validator.message('confirm password', value.confirm_password, `in:${value.password}`)} */}
                            {/* {validator.message(
        'confirm password',
        value.confirm_password,
        `in:${value.password}`,
        {
            default: 'The selected confirm password must be the same',
        }
    )} */}
     {value.password !== value.confirm_password && (
        <span className="errorMessage">
            The selected confirm password must be the same.
        </span>
    )}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="formFooter">
                                <Button fullWidth className="cBtn cBtnLarge cBtnTheme" type="submit">Sign Up</Button>
                            </Grid>
                            {/* <Grid className="loginWithSocial">
                                <Button className="facebook"><i className="fa fa-facebook"></i></Button>
                                <Button className="twitter"><i className="fa fa-twitter"></i></Button>
                                <Button className="linkedin"><i className="fa fa-linkedin"></i></Button>
                            </Grid> */}
                            <p className="noteHelp">Already have an account? <Link to="/login">Return to Sign In</Link>
                            </p>
                        </Grid>
                    </Grid>
                </form>
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>
        <Footer/> 
            <Scrollbar/>
        </Fragment>
    )
};

export default SignUpPage;