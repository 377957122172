import React from 'react';
import { Link } from 'react-router-dom'
import Destinations from '../../api/destination'
import { useNavigate } from "react-router-dom";



const Destination2 = (props) => {
    const navigate = useNavigate();
    function route(id) {
        navigate(`/destination-single/${id}`);
        }
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className="wpo-destination-area pt-120">
            <div className="container">
                <div className="destination-wrap">
                    <div className="row">
                        {Destinations.slice(0, 11).map((destination, ditem) => (
                            <div className="col-lg-4 col-md-6 col-12" key={ditem} style={{cursor:'pointer'}} onClick={() => route(destination.id)}>
                                <div className="destination-item" style={{minHeight:'69vh'}}>
                                    <div style={{cursor:'pointer'}} className="destination-img" onClick={() => route(destination.id)}>
                                        <img src={destination.dimg1} alt=""/>
                                    </div>
                                    <div className="destination-content">
                                        <span className="sub">{destination.subTitle}</span>
                                        <h2><Link onClick={ClickHandler} to={`/destination-single/${destination.id}`}>{destination.title}</Link></h2>
                                        {/* <div className="destination-bottom">
                                            <p>${destination.price} Per Night</p>
                                            <div className="destination-bottom-right">
                                                <ul>
                                                    <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                    <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                    <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                    <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                    <li><span><i className="fa fa-star" aria-hidden="true"></i></span></li>
                                                </ul>
                                                <small>4.5</small>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    )

}

export default Destination2;
