import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/fotlogo.png'
import Services from '../../api/service'


const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const callPhoneNumber = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };

    // Function to compose an email
    const composeEmail = (emailAddress) => {
        window.location.href = `mailto:${emailAddress}`;
    };
    return (
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="footer" />
                                </div>
                                <p style={{textAlign:'justify'}}>Step into a world of luxury at Chira Meadows Ecoresort. Tailored for those who seek refinement, our resort harmonizes business and leisure, offering an exclusive heaven for success and relaxation.</p>
                                <ul>
                                    <li>
                                        <Link onClick={ClickHandler} to="/">
                                            <i className="ti-facebook"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/">
                                            <i className="ti-twitter-alt"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/">
                                            <i className="ti-instagram"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/">
                                            <i className="ti-google"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget s1">
                                <div className="widget-title">
                                    <h3>AMENITIES</h3>
                                </div>
                                <ul>
                                    {Services.slice(0,7).map((service, sitem) => (
                                        <li key={sitem}><Link onClick={ClickHandler} to={`/service-single/${service.id}`}>{service.title}</Link></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Important Links</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/blog-fullwidth">Blog</Link></li>
                                    <li><Link onClick={ClickHandler} to="/destination">Attractions</Link></li>
                                    <li><Link onClick={ClickHandler} to="/room">Awesome Rooms</Link></li>
                                    <li><Link onClick={ClickHandler} to="/amenities">Amenities</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Contact Us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/privacy-policy">Privacy Policy</Link></li>
                                    {/* <li><Link onClick={ClickHandler} to="/pricing">Pricing Plan</Link></li> */}
                                </ul>
                            </div>
                        </div>

                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3>Contact </h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <li><i className="fi flaticon-placeholder"></i>Chira Meadows Ecoresort,
Om Sai Nagar, Dapoli, Dist Ratnagiri,
Jalgaon, Maharashtra 415712</li>
                                        {/* <li><i className="fi flaticon-phone-call"></i>+91-7030440505</li> */}
                                        {/* <li>+91-9423010005</li> */}
                                        <li><i className="fi flaticon-phone-call"></i><a href="#" style={{textDecoration:'none',color:'#e5e3e3'}} onClick={() => callPhoneNumber('+91-7030440505')}>+91-7030440505</a></li>
                                        <li> <a href="#" style={{textDecoration:'none',color:'#e5e3e3'}} onClick={() => callPhoneNumber('+91-9423010005')}>+91-9423010005</a></li>
                                        <li><i className="fi flaticon-send"></i><a style={{textDecoration:'none',color:'#e5e3e3'}} href="#" onClick={() => composeEmail('chirameadows@gmail.com')}>chirameadows@gmail.com</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright"> Copyright &copy; 2024 All Rights Reserved CHIRA MEADOWS.</p>
                            {/* <p className="copyright"> Copyright &copy; 2021 Parador by <Link onClick={ClickHandler} to="/">wpOceans</Link>. */}
                                {/* All Rights Reserved.</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;