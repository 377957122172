import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Logo from '../../images/logo2.png'
import bg from '../../images/destination/roompage.jpg'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Destination2 from '../../components/Destination2';
import Newslatter from '../../components/Newslatter/Newslatter';
import { Helmet } from 'react-helmet-async';

const DestinationPage =() => {
    return(
        <Fragment>
             <Helmet>
        <title>Attractions | Chira Meadows</title>
        <meta name="description" content="Explore the best attractions near Chira Meadows. From scenic beaches and historical forts to picturesque hills and local markets, Dapoli offers a variety of experiences that cater to every traveler's desire. Create unforgettable memories just a stone's throw away from our resort." />
        <meta name="keywords" content="attractions near Chira Meadows, things to do in Dapoli, scenic beaches, historical sites, local markets, adventure, sightseeing in Dapoli" />
        <meta property="og:title" content="Attractions | Chira Meadows" />
        <meta property="og:description" content="Explore the best attractions near Chira Meadows. From scenic beaches and historical forts to picturesque hills and local markets, Dapoli offers a variety of experiences that cater to every traveler's desire. Create unforgettable memories just a stone's throw away from our resort." />
        <meta property="og:type" content="website" />
    </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={'Destinations'} pagesub={'Destinations'} bg={bg}/> 
            <Destination2/>
            {/* <Newslatter nClass={'section-bg'}/> */}
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default DestinationPage;
