import React, {Fragment} from 'react';
import Offset from '../../components/Offset';
import Footer from '../../components/footer';
import Navbar from '../../components/Navbar';
import Scrollbar from '../../components/scrollbar';
import Logo from '../../images/logo.png';
import PageTitle from '../../components/pagetitle/PageTitle';
import bg from '../../images/destination/roompage.jpg'

const OffsetPage =() => {

    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo}/> 
            {/* <PageTitle pageTitle={'Offset'} pagesub={'Offset'} bg={bg}/>  */}
            <Offset/> 
            <Footer/> 
            <Scrollbar/>
        </Fragment>
    )
};

export default OffsetPage;