import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/about.jpg'

const About2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="wpo-about-section section-padding">
            <div className="container">
                <div className="wpo-about-section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-img">
                                <img src={abimg} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-content">
                                <div className="about-title">
                                    <span>SUSTAINABLE INDULGENCE AT CHIRA MEADOWS ECO RESORT.</span>
                                    <h2>CHIRA MEADOWS ECORESORT</h2>
                                </div>
                                <div className="wpo-about-content-inner">
                                    <p>Welcome to Chira Meadows Ecoresort, an off-beat destination in Dapoli, Maharashtra that connects you with nature. Our sustainable resort offers a range of activities that allow you to immerse yourself in the surrounding beauty. From farm-to-table dining to a wellness spa, we strive to provide an eco-friendly experience. Located in the serene Konkan region, our resort offers breathtaking views and is the perfect destination to escape the city and connect with nature. Come and experience the raw beauty of Chira Meadows Eco Resort.</p>
                                    {/* <div className="about-info-wrap">
                                        <div className="about-info-left">
                                            <p>2 Days / 3 Night</p>
                                            <ul>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><span><i className="fa fa-star" aria-hidden="true"></i></span></li>
                                            </ul>
                                        </div>
                                        <div className="about-info-right">
                                            <p>Only</p>
                                            <h3>$2500</h3>
                                        </div>
                                    </div> */}
                                    <Link className="theme-btn" onClick={ClickHandler} to='/room' style={{marginTop:'1rem'}}>Book Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About2;