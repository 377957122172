import React, { useEffect } from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import {toast} from "react-toastify";
import {Link, useNavigate} from "react-router-dom";
import { NavLink } from 'react-router-dom';
import './style.css'

const Sidebar = () => {
    const push = useNavigate()
    const CheckHandler = () => {
        // toast.success("Admin Logout Successful");
        const role = localStorage.getItem("role");
        if(role !== "admin")
        {
            push('/home');
            window.location.reload();
            window.scrollTo(10, 0);
        }
    }
    useEffect(() => {
        CheckHandler();
    }, [])
    
    const LogoutHandler = () => {
        localStorage.clear();
        toast.success("Admin Logout Successful");
        push('/home');
        window.location.reload();
        window.scrollTo(10, 0);
    }
  return (
    <CDBSidebar textColor="#fff" backgroundColor="#333">
    <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
      <a
        href="/"
        className="text-decoration-none"
        style={{ color: 'inherit' }}
      >
        Chira Meadows
      </a>
    </CDBSidebarHeader>

    <CDBSidebarContent className="sidebar-content">
      <CDBSidebarMenu>
        <NavLink exact to="/dashboard" activeClassName="activeClicked">
          <CDBSidebarMenuItem icon="columns" className="custom-sidebar-item">Dashboard</CDBSidebarMenuItem>
        </NavLink>
        {/* <NavLink exact to="/tables" activeClassName="activeClicked">
          <CDBSidebarMenuItem icon="table">Tables</CDBSidebarMenuItem>
        </NavLink> */}
        <NavLink exact to="/allusers" activeClassName="activeClicked">
          <CDBSidebarMenuItem icon="user" className="custom-sidebar-item">Users</CDBSidebarMenuItem>
        </NavLink>
        <NavLink exact to="/allcontacts" activeClassName="activeClicked">
          <CDBSidebarMenuItem icon="chart-line" className="custom-sidebar-item">
            Contacts
          </CDBSidebarMenuItem>
        </NavLink>
        <NavLink exact to="/allrooms" activeClassName="activeClicked">
          <CDBSidebarMenuItem icon="sticky-note" className="custom-sidebar-item">
            Rooms
          </CDBSidebarMenuItem>
        </NavLink>

        {/* <NavLink
          exact
          to="/hero404"
          target="_blank"
          activeClassName="activeClicked"
        >
          <CDBSidebarMenuItem icon="exclamation-circle">
            404 page
          </CDBSidebarMenuItem>
        </NavLink> */}
      </CDBSidebarMenu>
    </CDBSidebarContent>

    <CDBSidebarFooter style={{ textAlign: 'center' }}>
      <div onClick={LogoutHandler}
        style={{
          padding: '20px 5px',
          display: 'flex',
          justifyContent:'center',
          alignItems:'center',
          cursor:'pointer'
        }}
      > 
      <i class="fi fi-bs-sign-out-alt"></i>&nbsp; Sign Out
      </div>
    </CDBSidebarFooter>
  </CDBSidebar>
  );
};

export default Sidebar;