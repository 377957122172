import React, {Fragment} from 'react';
import About2 from '../../components/about2/about2';
import BlogSection from '../../components/BlogSection';
import Destination from '../../components/Destination';
import Footer from '../../components/footer';
import FunFact from '../../components/FunFact';
import Hero2 from '../../components/hero2/hero2';
import Navbar from '../../components/Navbar'
import Newslatter from '../../components/Newslatter/Newslatter';
import PricingSection from '../../components/PricingSection';
import Rooms from '../../components/Rooms/Rooms';
import Scrollbar from '../../components/scrollbar'
import SearchSection from '../../components/SearchSection';
import ServiceSection2 from '../../components/ServiceSection2/ServiceSection2';
import Testimonial from '../../components/Testimonial';
import Logo from '../../images/logo.png'
import ServiceSection from '../../components/ServiceSection';
import Hero from '../../components/hero';
// import Pop from '../../components/POP/Pop';
import { Helmet } from 'react-helmet-async';


const HomePage2 =() => {

    return(
        <Fragment>
               <Helmet>
               <title>Chira Meadows</title>
                <meta name="description" content="Discover the serene beauty of Chira Meadows, an eco-friendly resort nestled in the lush hills of Dapoli. Enjoy our spacious luxury accommodations, gourmet dining, and a range of services designed for relaxation and adventure. Experience nature at its finest with breathtaking views, warm hospitality, and unforgettable memories." />
                <meta name="keywords" content="Chira Meadows, eco-resort in Dapoli, luxury accommodations, nature retreat, services, beautiful landscapes, relaxation, adventure activities" />
                <meta property="og:title" content="Chira Meadows" />
                <meta property="og:description" content="Discover the serene beauty of Chira Meadows, an eco-friendly resort nestled in the lush hills of Dapoli. Enjoy our spacious luxury accommodations, gourmet dining, and a range of services designed for relaxation and adventure. Experience nature at its finest with breathtaking views, warm hospitality, and unforgettable memories." />
                <meta property="og:type" content="website" />
            </Helmet>
            {/* <Pop/> */}
            <Navbar topbarBlock={'wpo-header-style-2'} Logo={Logo}/> 
            <Hero/> 
            <SearchSection/> 
            <About2/> 
            <FunFact fClass={'section-padding'}/> 
            <Rooms/> 
            <ServiceSection2/> 
            {/* <PricingSection/>  */}
            <ServiceSection svClass={'section-padding'}/> 
            <Testimonial/> 
            <Destination/> 
            <BlogSection/> 
            {/* <Newslatter/>  */}
            <Footer/> 
            <Scrollbar/>
        </Fragment>
    )
};

export default HomePage2;