import React from 'react';
import ContactForm1 from '../ContactFrom1'
import { Link } from 'react-router-dom'
import abimg from '../../images/about.jpg'

const ContactpageSec = () => {
    const callPhoneNumber = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };

    // Function to compose an email
    const composeEmail = (emailAddress) => {
        window.location.href = `mailto:${emailAddress}`;
    };
    return (
        <section className="wpo-contact-pg-section">
        {/* <section className="wpo-contact-pg-section section-padding"> */}
            {/* <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="wpo-contact-title">
                            <h2>Subscribe & Get Special Discount!</h2>
                            <p>Don’t Wanna Miss Somethings? Subscribe Right Now And Get The Special Discount And Monthly Newsletter.</p>
                        </div>
                        <div className="wpo-contact-form-area">
                            <ContactForm1 />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* return ( */}
        <section className="wpo-about-section section-padding">
            <div className="container">
                <div className="wpo-about-section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-img">
                                <img src={abimg} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-content">
                                <div className="about-title">
                                    {/* <span>Subscribe & Get Special Discount!</span> */}
                                    <h2>Contact us and get special discounts on booking!</h2>
                                    {/* <h4>Contact us and get special discounts on booking!</h4> */}
                                    {/* <h2>Don’t Wanna Miss Somethings? Subscribe Right Now And Get The Special Discount And Monthly Newsletter.</h2> */}
                                </div>
                                <div className="wpo-about-content-inner" style={{marginTop:'2rem'}}>
                                <ContactForm1 />
                                    {/* <p>Welcome to Chira Meadows Ecoresort, an off-beat destination in Dapoli, Maharashtra that connects you with nature. Our sustainable resort offers a range of activities that allow you to immerse yourself in the surrounding beauty. From farm-to-table dining to a wellness spa, we strive to provide an eco-friendly experience. Located in the serene Konkan region, our resort offers breathtaking views and is the perfect destination to escape the city and connect with nature. Come and experience the raw beauty of Chira Meadows Eco Resort.</p> */}
                                    {/* <div className="about-info-wrap">
                                        <div className="about-info-left">
                                            <p>2 Days / 3 Night</p>
                                            <ul>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                <li><span><i className="fa fa-star" aria-hidden="true"></i></span></li>
                                            </ul>
                                        </div>
                                        <div className="about-info-right">
                                            <p>Only</p>
                                            <h3>$2500</h3>
                                        </div>
                                    </div> */}
                                    {/* <Link className="theme-btn" onClick={ClickHandler} to='/room' style={{marginTop:'1rem'}}>Book Now</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item" style={{ height: '100%' }}>
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-maps-and-flags"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Address</h2>
                                            <p>Chira Meadows Ecoresort,
                                                Om Sai Nagar, Dapoli, Dist Ratnagiri,
                                                Jalgaon, Maharashtra 415712</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item" style={{ height: '100%' }}>
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Email Us</h2>
                                            <p style={{ textAlign: 'center' }}><a style={{ textDecoration: 'none' }} href="#" onClick={() => composeEmail('chirameadows@gmail.com')}>chirameadows@gmail.com</a></p>
                                            {/* <p>helloyou@gmail.com</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item" style={{ height: '100%' }}>
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Call Now</h2>
                                            <p style={{ textAlign: 'center' }}><a href="#" style={{ textDecoration: 'none' }} onClick={() => callPhoneNumber('+91-7030440505')}>+91-7030440505</a></p>
                                            <p style={{ textAlign: 'center' }}><a href="#" style={{ textDecoration: 'none' }} onClick={() => callPhoneNumber('+91-9423010005')}>+91-9423010005</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    {/* ) */}
            {/* <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                      </div>
            </section> */}
        </section>
    )

}

export default ContactpageSec;
