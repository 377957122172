import React, {useState,useEffect,Fragment} from 'react';
import Grid from "@mui/material/Grid";
import SimpleReactValidator from "simple-react-validator";
import {toast} from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import Sidebar from '../Sidebar/Sidebar.jsx';
// import Sidebar from './Sidebar.jsx';
import Logo from '../../images/logo.png'
import Scrollbar from '../../components/scrollbar'
import './style.scss';
import ReactCustomTable from '../../components/ReactCustomTable/ReactCustomTable';

const AdminRoomPage = (props) => {
 
    const [allContactsData, setAllContactsData] = useState([]);
    const [allContactsDataLoading, setAllContactsDataLoading] = useState(true);

    useEffect(() => {
        fetchAllContacts();
    }, [])
    const tableActions = [

        {
            label: 'Update',
            key: 'update',
            name: 'Update',
            // width: 300,
            // name: "Edit",
            btnType:'update',
            allowOverflow: true,
            selector: false
        },
    ]
    const fetchAllContacts = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}/api/price`)
            .then(res => {
                if (!res.data.success) return toast.error("Error, Please Try Again !!!");
                setAllContactsData(res.data.data)
                setAllContactsDataLoading(false);
            })
            .catch(err => {
                console.log(err);
                setAllContactsDataLoading(false);
                return toast.error("Something Went Wrong, Please Try Again !!!");
            })
    }
  return (
    <Fragment>
    <div
  style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}
>
    <Sidebar />
    
  <Grid className="loginWrapper" style={{width:'83%',display:'flex',flexDirection:'column'}}>
  <h2 style={{marginLeft:'1rem'}}>All Rooms</h2>
        <ReactCustomTable data={allContactsData} tableColumns={tableColumns} loading={allContactsDataLoading}
        minRows={0}
        showPagination={true}
        tableActions={tableActions} actionsColumnWidth={100}
        // tableActions={tableActions} actionsColumnWidth={120}
        // deleteID={deleteID} deleteLoading={deleteLoading} rowDataDeleteKey='classid' circularColor='#d32f2f'
        />
    </Grid>
    </div>
  {/* <Footer/>  */}
      <Scrollbar/>
  </Fragment>
  )
};

export default AdminRoomPage;

const tableColumns = [
    {
        label: 'ID',
        key: 'id',
        width: 50,
        fixed: true,
        align: 'center',
        resizable: false
    },
    {
        label: 'Name',
        key: 'name',
        flexGrow: 1,
        fixed: true,
        fullText: true,
        minWidth: 200,
        resizable: true
    },
    {
        label: 'Price',
        key: 'price',
        width: 500,
        fullText: true,
        resizable: true
    }
]