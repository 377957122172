import simg1 from '../images/activity/1.jpg'
import simg2 from '../images/activity/2.jpg'
import simg3 from '../images/activity/3.jpg'
import simg4 from '../images/activity/4.jpg'
import simg5 from '../images/activity/5.jpg'
import simg6 from '../images/activity/6.jpg'
import simg7 from '../images/activity/7.jpg'
import simg8 from '../images/activity/8.jpg'
import simg9 from '../images/activity/9.jpg'

import singleImg1 from '../images/service-single/1.jpg'
import singleImg2 from '../images/service-single/2.jpg'
import singleImg3 from '../images/service-single/3.jpg'
import singleImg4 from '../images/service-single/4.jpg'
import singleImg5 from '../images/service-single/5.jpg'
import singleImg6 from '../images/service-single/6.jpg'
import singleImg7 from '../images/service-single/7.jpg'

import fimg from '../images/activity/darts.png'





const Activities = [
    {
        id: '1',
        // type:'download',
        // fimg:fimg,
        fIcon:'fi-rr-bow-arrow',
        title:'BHUTANESE DART GAME',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg1,
        ssImg:singleImg1,
    },
    {
        id: '2',
        fIcon:'fi-rr-cake-wedding',
        title:'DESTINATION WEDDINGS',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg2,
        ssImg:singleImg2,
    },
    {
        id: '3',
        fIcon:'fi-rr-crown',
        title:'CELEBTRATION OF YOUR SPECIAL OCCASION',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg3,
        ssImg:singleImg3,
    },
    {
        id: '4',
        fIcon:'fi-rr-picture',
        title:'WILDLIFE PHOTOGRAPHY',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg4,
        ssImg:singleImg4,
    },
    {
        id: '5',
        fIcon:'fi-rr-fishing-rod',
        title:'FISHING AND CRAB HUNTING',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg5,
        ssImg:singleImg5,
    },
    {
        id: '6',
        fIcon:'fi-rr-dolphin',
        title:'DOLPHIN WATCH',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg6,
        ssImg:singleImg6,
    },
    {
        id: '7',
        fIcon:'fi-rr-mug-hot-alt',
        title:'LOCAL FOOD FESTIVAL',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg7,
        ssImg:singleImg7,
    },
    {
        id: '8',
        fIcon:'fi-rr-utensils',
        title:'POPTI',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg8,
        ssImg:singleImg1,
    },
    {
        id: '9',
        fIcon:'fi-rr-acorn',
        title:'COCONUT & ARECANUT',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg9,
        ssImg:singleImg1,
    }
]
export default Activities;