import React, {useState,useEffect,Fragment} from 'react';
import {toast} from "react-toastify";
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar.jsx';
import Scrollbar from '../../components/scrollbar';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import './Contact_view.css';
import { useParams } from 'react-router-dom';

export default function CreateEvent(props) {
    const { id } = useParams();
    console.log(id)
    const jsonData = {
        "id": id,
    }

    const [allContactsData, setAllContactsData] = useState([]);
    const [allContactsDataLoading, setAllContactsDataLoading] = useState(true);

    useEffect(() => {
        fetchAllContacts();
    }, [])
    const fetchAllContacts = async () => {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/api/getcontactbyid`, jsonData)
            .then(res => {
                if (!res.data.success) return toast.error("Error, Please Try Again !!!");
                setAllContactsData(res.data.data[0])
                setAllContactsDataLoading(false);
                console.log(res.data.data[0])
            })
            .catch(err => {
                console.log(err);
                setAllContactsDataLoading(false);
                return toast.error("Something Went Wrong, Please Try Again !!!");
            })
    }
    // const onChange1 = (e) => {
    //   };

    return (
        <Fragment>
        <div
      style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}
    >
        <Sidebar />
        
      <Grid className="loginWrapper" style={{width:'83%',display:'flex',flexDirection:'column'}}>

<Grid className="loginForm">
    <h2>Contact Details</h2>
    {/* <p>Signup your account</p> */}
    <form >
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    className="inputOutline"
                    fullWidth
                    disabled
                    placeholder="Name"
                    value={allContactsData['name']}
                    variant="outlined"
                    name="name"
                    label="Name"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    // onBlur={(e) => changeHandler(e)}
                    // onChange={(e) => changeHandler(e)}
                />
                {/* {validator.message('full name', value.full_name, 'required')} */}
                {/* {validator.message('full name', value.full_name, 'required|alpha')} */}
            </Grid>
           
            <Grid item xs={12}>
                <TextField
                    className="inputOutline"
                    fullWidth
                    disabled
                    placeholder="Phone Number"
                    value={allContactsData['phone']}
                    variant="outlined"
                    name="phone"
                    label="Phone Number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    // onBlur={(e) => changeHandler(e)}
                    // onChange={(e) => changeHandler(e)}
                />
                {/* {validator.message('Phone No', value.phone_no, 'required|numeric')} */}
                {/* {validator.message('full name', value.full_name, 'required|alpha')} */}
            </Grid>
            <Grid item xs={12}>
                <TextField
                    className="inputOutline"
                    fullWidth
                    disabled
                    placeholder="E-mail"
                    value={allContactsData['email']}
                    variant="outlined"
                    name="email"
                    label="E-mail"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    // onBlur={(e) => changeHandler(e)}
                    // onChange={(e) => changeHandler(e)}
                />
                {/* {validator.message('email', value.email, 'required|email')} */}
            </Grid>
            <Grid item xs={12}>
                <TextField
                    className="inputOutline"
                    fullWidth
                    disabled
                    placeholder="Subject"
                    value={allContactsData['subject']}
                    variant="outlined"
                    name="subject"
                    label="Subject"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    // onBlur={(e) => changeHandler(e)}
                    // onChange={(e) => changeHandler(e)}
                />
                {/* {validator.message('age', value.age, 'required|numeric')} */}
                {/* {validator.message('full name', value.full_name, 'required|alpha')} */}
            </Grid>
            <Grid item xs={12}>
            <div className="col col-lg-12 col-12">
                    <textarea
                        // onBlur={(e) => changeHandler(e)}
                        // onChange={(e) => changeHandler(e)}
                        value={allContactsData['message']}
                        type="text"
                        fullWidth
                        rows={5}
                        // cols={40}
                        disabled
                        name="message"
                        style={{ width: '100%' }}
                        placeholder="Message">
                    </textarea>
                    </div>
                {/* <TextField
                    className="inputOutline"
                    fullWidth
                    disabled
                    placeholder="Message"
                    type='text'
                    value={allContactsData['message']}
                    variant="outlined"
                    name="message"
                    label="Message"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    // onBlur={(e) => changeHandler(e)}
                    // onChange={(e) => changeHandler(e)}
                /> */}
            </Grid>
                {/* {validator.message('password', value.password, 'required')} */}
            {/* <Grid item xs={12}> */}
                {/* <Grid className="formFooter">
                    <Button fullWidth className="cBtn cBtnLarge cBtnTheme" type="submit">Update</Button>
                </Grid> */}
                
                {/* <p className="noteHelp">Already have an account? <Link to="/login">Return to Sign In</Link>
                </p> */}
            {/* </Grid> */}
        </Grid>
    </form>
    <div className="shape-img">
        <i className="fi flaticon-honeycomb"></i>
    </div>
</Grid>
</Grid>
            {/* <h1 align="center" style={{
                fontSize: '32px',
            }}>Contact Details</h1>

            <div className="contact_view_style_main_div event_input mb-3 pt-0">
                <div className="conatact_view_div">
                    Id:
                </div>
                <div>
                    {allContactsData['id']}
                </div>
            </div>
            <div className="contact_view_style_main_div event_input mb-3 pt-0">
                <div className="conatact_view_div">
                    Name:
                </div>
                <div>
                    {allContactsData['name']}
                </div>
            </div>
            <div className="contact_view_style_main_div event_input mb-3 pt-0">
                <div className="conatact_view_div">
                    Email:
                </div>
                <div>
                    {allContactsData['email']}
                </div>
            </div>
            <div className="contact_view_style_main_div event_input mb-3 pt-0">
                <div className="conatact_view_div">
                    Phone Number:
                </div>
                <div>
                    {allContactsData['phone']}
                </div>
            </div>
            <div className="contact_view_style_main_div event_input mb-3 pt-0">
                <div className="conatact_view_div">
                    Subject:
                </div>
                <div>
                    {allContactsData['subject']}
                </div>
            </div>
            <div className="contact_view_style_main_div event_input mb-3 pt-0">
                <div className="conatact_view_div">
                    Message:
                </div>
                <div>
                    {allContactsData['message']}
                </div>
            </div> */}
            

        </div>
      {/* <Footer/>  */}
          <Scrollbar/>
      </Fragment>
    )
}
