import React, { useState, useEffect, Fragment } from 'react';
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar.jsx';
import Scrollbar from '../../components/scrollbar';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SimpleReactValidator from "simple-react-validator";
import './Room_update.css';
import { useParams, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

export default function Room_update(props) {
    const location = useLocation();
    const { id } = useParams();
    const navigate = useNavigate()
    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const [value, setValue] = useState({
        name: '',
        price: ''
    });
    const changeHandler = (e) => {
        setValue({...value, [e.target.name]: e.target.value});
        validator.showMessages();
    };
    useEffect(() => {
        const rowData = location?.state;
        console.log(location.state)
        setValue(rowData)
    }, [])
    const updateRoom = async (e) => {
        e.preventDefault();
        // const id = id1;
        const name = value.name;
        const price = value.price;
        if (validator.allValid()) {
        await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/price`, {
            id,
            name,
            price
        })
            .then(res => {
                console.log(res.data.data);
                if (!res.data.success) return toast.error("Error, Please Try Again !!!");
                toast.success("Room Updated Successfully !!!");
                navigate('/allrooms');
                // window.location.reload();
            })
            .catch(err => {
                console.log(err);
                return toast.error("Something Went Wrong, Please Try Again !!!");
            })
        } else {
            validator.showMessages();
            toast.error('Empty field is not allowed!');
        }
    }
    // const onChange1 = (e) => {
    //   };

    return (
        <Fragment>
            <div
                style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}
            >
                <Sidebar />

                <Grid className="loginWrapper" style={{ width: '83%', display: 'flex', flexDirection: 'column' }}>

                    <Grid className="loginForm">
                        <h2>Room Details</h2>
                        {/* <p>Signup your account</p> */}
                        <form onSubmit={updateRoom}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        className="inputOutline"
                                        fullWidth
                                        disabled
                                        placeholder="Room Name"
                                        value={value.name}
                                        variant="outlined"
                                        name="name"
                                        label="Room Name"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    // onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                    />
                                    {validator.message('name', value.name, 'required')}
                                    {/* {validator.message('full name', value.full_name, 'required|alpha')} */}
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        className="inputOutline"
                                        fullWidth
                                        placeholder="Price"
                                        value={value.price}
                                        variant="outlined"
                                        name="price"
                                        label="Price"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    // onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                    />
                                    {validator.message('Price', value.price, 'required')}
                                    {/* {validator.message('full name', value.full_name, 'required|alpha')} */}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid className="formFooter">
                                        <Button fullWidth className="cBtnTheme" type="submit">Update</Button>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </form>
                        <div className="shape-img">
                            <i className="fi flaticon-honeycomb"></i>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {/* <Footer/>  */}
            <Scrollbar />
        </Fragment>
    )
}
