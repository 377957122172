import React, {useState,Fragment} from 'react';
import Grid from "@mui/material/Grid";
import SimpleReactValidator from "simple-react-validator";
import {toast} from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {Link, useNavigate} from "react-router-dom";
import Logo from '../../images/logo.png'

import Scrollbar from '../../components/scrollbar'
import Footer from '../../components/footer';
import Navbar from '../../components/Navbar'
import axios from "axios";
import './style.scss';



const LoginPage = (props) => {

    const [loading, setLoading] = useState(false);
    const push = useNavigate()

    const [value, setValue] = useState({
        email: '',
        password: '',
        remember: false,
    });

    const changeHandler = (e) => {
        setValue({...value, [e.target.name]: e.target.value});
        validator.showMessages();
    };

    const rememberHandler = () => {
        setValue({...value, remember: !value.remember});
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));



    const submitForm = async (e) => {
        e.preventDefault();
        const email = value.email;
        const password = value.password;
        if (validator.allValid()) {
            setValue({
                email: '',
                password: '',
                remember: false
            });
            validator.hideMessages();
            
            // const userRegex = /^+.*/gm;
            const userRegex = /^.+@.+\.com$/gm;
            // const email = value.email;
            if (email.match(userRegex)) {
                try {
                    setLoading(true);
                    const res = await axios.post(
                            `${process.env.REACT_APP_BASE_URL}/api/sign-in`,
                            {
                                email,
                                password
                            }
                        );
                        if (res.data.success) {
                            const userData = { ...res.data.data, auth: res.data.auth };
                            // console.log(Preferences.get({ key: 'user_id' }))
                            // Save the user ID and role to local storage
                            localStorage.setItem("user_id", userData.id);
                            localStorage.setItem("role", userData.role);
                            localStorage.setItem("authToken", userData.auth);
                            localStorage.setItem("userData", JSON.stringify(userData));
                            // Set the loggedIn key to true to indicate user is logged in
                            localStorage.setItem("loggedIn", "true");
            
                            // Role-based redirection
                             if(userData.role === "user") {
                                toast.success("User Login Successful");
                                push('/home');
                                // history.push('/po/home');
                                // window.location.reload(); 
                            }
                            else{
                                toast.success("Admin Login Successful");
                                push('/dashboard');
                                // history.push('/admin/home');
                                // window.location.reload(); 
                            }
                                  //  window.location.reload(); 
                        } else {
                          console.log("Error Occurred");
                        //   toast.error("Error occurred while signing in. Please try again.");
                          toast.error(res.data.message);
                        }
                    } catch (error) {
                        console.log(error);
                        toast.error("Error occurred while signing in. Please try again.");
                    } finally {
                        setLoading(false);
                        // toast.success('You successfully Login on Parador !');
                        // push('/home');
                        window.location.reload();
                    }
            }
        } else {
            validator.showMessages();
            toast.error('Empty field is not allowed!');
        }
    };
    return (
        <Fragment>
        <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
        <Grid className="loginWrapper">
            <Grid className="loginForm">
                <h2>Sign In</h2>
                <p>Sign in to your account</p>
                <form onSubmit={submitForm}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="E-mail"
                                value={value.email}
                                variant="outlined"
                                name="email"
                                label="E-mail"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('email', value.email, 'required|email')}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Password"
                                value={value.password}
                                variant="outlined"
                                name="password"
                                type="password"
                                label="Password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('password', value.password, 'required')}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="formAction">
                                <FormControlLabel
                                    control={<Checkbox checked={value.remember} onChange={rememberHandler}/>}
                                    label="Remember Me"
                                />
                                <Link to="/forgot-password">Forgot Password?</Link>
                            </Grid>
                            <Grid className="formFooter">
                                <Button fullWidth className="cBtnTheme" type="submit">Login</Button>
                            </Grid>
                            {/* <Grid className="loginWithSocial">
                                <Button className="facebook"><i className="fa fa-facebook"></i></Button>
                                <Button className="twitter"><i className="fa fa-twitter"></i></Button>
                                <Button className="linkedin"><i className="fa fa-linkedin"></i></Button>
                            </Grid> */}
                            <p className="noteHelp">Don't have an account? <Link to="/register">Create free account</Link>
                            </p>
                        </Grid>
                    </Grid>
                </form>
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>
            <Footer/> 
            <Scrollbar/>
        </Fragment>
    )
};

export default LoginPage;