import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import { useParams } from 'react-router-dom'
import Navbar from '../../components/Navbar';
import Scrollbar from '../../components/scrollbar'
import offsets from '../../api/offsets'
import DestinationSidebar from './sidebar'
import Footer from '../../components/footer';
import Logo from '../../images/logo2.png'
import bg from '../../images/destination/roompage.jpg'

import dimg1 from '../../images/destination1/2.jpg'


const DestinationSinglePage = (props) => {
    const { id } = useParams()

    const destinationDetails = offsets.find(item => item.id === id)


    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={destinationDetails.title} pagesub={'offsite'} bg={bg}/>
            <section className="wpo-destination-single-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="wpo-destination-single-wrap">
                                <div className="wpo-destination-single-content">
                                    {/* <img src={destinationDetails.dSimg} alt="" /> */}
                                    <div className="wpo-destination-single-content-des" style={{padding:'0px'}}>
                                        <h2>Images</h2>
                                        {/* <h2>{destinationDetails.title}</h2> */}
                                        {/* <p>{destinationDetails.para1} </p> */}
            
                                        <div className="wpo-destination-single-sub-img">
                                            <ul>
                                                {
                                                    destinationDetails.imgs.length > 0 &&
                                                    (destinationDetails.imgs).slice(0, 2).map((destination, ditem) => (
                                                        <li key={ditem}><img src={`${process.env.REACT_APP_AWS}${destination}`} alt={dimg1} /></li>
                                                ))}
                                                 {/* <li><img src={dimg2} alt="" /></li> */}
                                            </ul>

                                        </div>
                                        <div className="wpo-destination-single-sub-img">
                                            <ul>
                                            {
                                                    destinationDetails.imgs.length > 0 &&
                                                    (destinationDetails.imgs).slice(2, 4).map((destination, ditem) => (
                                                        <li key={ditem}><img src={`${process.env.REACT_APP_AWS}${destination}`} alt={dimg1} /></li>
                                                ))}
                                            </ul>

                                        </div>
                                        <div className="wpo-destination-single-sub-img">
                                            <ul>
                                            {
                                                    destinationDetails.imgs.length > 0 &&
                                                    (destinationDetails.imgs).slice(4, 6).map((destination, ditem) => (
                                                        <li key={ditem}><img src={`${process.env.REACT_APP_AWS}${destination}`} alt={dimg1} /></li>
                                                ))}
                                            </ul>

                                        </div>
                                        <div className="wpo-destination-single-sub-img">
                                            <ul>
                                            {
                                                    destinationDetails.imgs.length > 0 &&
                                                    (destinationDetails.imgs).slice(6, 8).map((destination, ditem) => (
                                                        <li key={ditem}><img src={`${process.env.REACT_APP_AWS}${destination}`} alt={dimg1} /></li>
                                                ))}
                                            </ul>

                                        </div>
                                        <div className="wpo-destination-single-sub-img">
                                            <ul>
                                            {
                                                    destinationDetails.imgs.length > 0 &&
                                                    (destinationDetails.imgs).slice(8, 10).map((destination, ditem) => (
                                                        <li key={ditem}><img src={`${process.env.REACT_APP_AWS}${destination}`} alt={dimg1} /></li>
                                                ))}
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                                {/* <p>{destinationDetails.para2}</p> */}
                                {/* <Benefits /> */}

                            </div>
                        </div>
                        {destinationDetails.utube?.length > 0 &&
                        <DestinationSidebar details={destinationDetails}/>
                        }
                    </div>
                </div>
            </section>
            {/* <Newslatter nClass={'section-bg'}/> */}
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default DestinationSinglePage;
