import './ReactCustomTable.css';
import { Fragment } from 'react';
// import { CircularProgress } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { useEffect, useState } from 'react';
import {toast} from "react-toastify";
import Button from "@mui/material/Button";
import axios from 'axios';

import { Link } from 'react-router-dom'
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css'

import {useNavigate} from "react-router-dom";
// const {  } = Table;

function ReactCustomTable({
    data, tableColumns, loading, tableActions, actionsColumnWidth, onRowClick, circularColor,
    deleteID, deleteLoading, rowDataDeleteKey // Delete
}) {
    const [page, setPage] = useState(1);
    const itemsPerPage = 5;

    const navigate = useNavigate()
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = data.slice(startIndex, endIndex);

    const handleClick = async (e) => {
        const jsonData = {
            "id": e,
        }
        console.log(e);
        const authToken = localStorage.getItem('authToken');;
        try {
        const res = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/user`, 
        {
            data: jsonData,
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
        if (!res.data.success) {
            toast.error("Error, Please Try Again !!!");
        } else {
            toast.success("User Deleted Successfully !!!");
        }
    } catch (err) {
        console.log(err);
        toast.error("Something Went Wrong, Please Try Again !!!");
    }
        window.location.reload();
    }
    const handleEdit = (row_data) => {
        navigate(`/admin/room-update/${row_data.id}`, { state: row_data });
      };
    return (
        <>
        <Table
            style={{width:'100%'}}
            affixHeader
            autoHeight
            bordered
            data={paginatedData}
            loading={loading}
            onRowClick={onRowClick ? rowData => onRowClick(rowData) : () => { }}
        >
            {tableColumns?.map((c, index) => (
                <Column width={c?.width} fixed={c?.fixed} flexGrow={c?.flexGrow} align={c?.align || 'left'} fullText={c?.fullText}
                    resizable={c?.resizable} minWidth={c?.minWidth} key={index}
                >
                    <HeaderCell className='reactCustomTable_label' >{c?.label}</HeaderCell>
                    <Cell dataKey={c?.key} className='reactCustomTable_cell' />
                </Column>
            ))}

            {tableActions && (
                <Column width={actionsColumnWidth}  fixed="right" align='center' >
                    <HeaderCell>Actions</HeaderCell>

                    <Cell>
                        {rowData => (
                            <div className='rsuite_actions_btnCont' >
                                {tableActions?.map((t, i) => (
                                    <Fragment key={i}>
                                        {
                                            // rowData[rowDataDeleteKey] == deleteID && deleteLoading &&
                                            t?.btnType == 'del' ? (
                                                // <CircularProgress size={18} style={{ color: circularColor, marginRight: 10 }} />
                                                
                                <Button fullWidth className="cBtnTheme"  onClick={(e) => handleClick(rowData.id)}>{t?.name}</Button>
                                                // <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={(e) => handleClick(rowData.id)} >
                                                //     {t?.name}
                                                // </button>
                                            ) : (t?.btnType == 'view' ? (
                                                // <CircularProgress size={18} style={{ color: circularColor, marginRight: 10 }} />
                                <Button fullWidth className="cBtnTheme">
                                    <Link style={{color:'white'}} to=
                                                        {{
                                                            pathname: `/contact/view/${rowData.id}`
                                                        }}>
                                                        {t?.name}
                                                    </Link>
                                </Button>
                                                // <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                                                //    <Link to=
                                                //         {{
                                                //             pathname: `/admin/contact/view/${rowData.id}`
                                                //         }}>
                                                //         {t?.name}
                                                //     </Link>
                                                // </button>
                                            ) : (
                                                // <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={(e) => t.onClick(e, rowData)} >
                                                <Button fullWidth className="cBtnTheme" onClick={(e) => handleEdit(rowData)}>
                                                {/* <Link to=
                                                                    {{
                                                                        pathname: `/admin/room-update/${rowData.id}`,
                                                                        state: { Data: rowData }
                                                                    }}> */}
                                                                    {t?.name}
                                                                {/* </Link> */}
                                            </Button>
                                            //    <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" >
                                            //         <Link to=
                                            //             {{
                                            //                 pathname: `/admin/event-update/id/${rowData.id}`
                                            //             }}>
                                            //             {t?.name}
                                            //         </Link>
                                            //     </button>
                                            ))}
                                    </Fragment>
                                ))}
                            </div>
                        )}
                    </Cell>
                </Column>
            )}
        </Table>
        <Pagination
                count={Math.ceil(data.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                variant="outlined"
                shape="rounded"
            />
    </>
    )
}

export default ReactCustomTable;