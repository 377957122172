import './Contact.css';
import React, {useState,useEffect,Fragment} from 'react';
import {toast} from "react-toastify";
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar.jsx';
import Scrollbar from '../../components/scrollbar';
import Grid from "@mui/material/Grid";
// Components

import ReactCustomTable from '../../components/ReactCustomTable/ReactCustomTable';

function Contact() {

    const [allContactsData, setAllContactsData] = useState([]);
    const [allContactsDataLoading, setAllContactsDataLoading] = useState(true);

    useEffect(() => {
        fetchAllContacts();
    }, [])
    const tableActions = [

        {
            label: 'View',
            key: 'view',
            name: 'View',
            // width: 300,
            // name: "Edit",
            btnType:'view',
            allowOverflow: true,
            selector: false
        },
    ]
    const fetchAllContacts = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}/api/contact`)
            .then(res => {
                if (!res.data.success) return toast.error("Error, Please Try Again !!!");
                setAllContactsData(res.data.data)
                setAllContactsDataLoading(false);
            })
            .catch(err => {
                console.log(err);
                setAllContactsDataLoading(false);
                return toast.error("Something Went Wrong, Please Try Again !!!");
            })
    }

    return (
        <Fragment>
        <div
      style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}
    >
        <Sidebar />
        
      <Grid className="loginWrapper" style={{width:'83%',display:'flex',flexDirection:'column'}}>
      <h2 style={{marginLeft:'1rem'}}>All Contacts</h2>
            <ReactCustomTable data={allContactsData} tableColumns={tableColumns} loading={allContactsDataLoading}
            minRows={0}
            showPagination={true}
            tableActions={tableActions} actionsColumnWidth={100}
            // tableActions={tableActions} actionsColumnWidth={120}
            // deleteID={deleteID} deleteLoading={deleteLoading} rowDataDeleteKey='classid' circularColor='#d32f2f'
            />
        </Grid>
        </div>
      {/* <Footer/>  */}
          <Scrollbar/>
      </Fragment>
    )
}

export default Contact

const tableColumns = [
    {
        label: 'ID',
        key: 'id',
        width: 50,
        fixed: true,
        align: 'center',
        resizable: false
    },
    {
        label: 'Name',
        key: 'name',
        flexGrow: 1,
        fixed: true,
        fullText: true,
        minWidth: 200,
        resizable: true
    },
    {
        label: 'Phone No.',
        key: 'phone',
        width: 200,
        align: 'center',
        resizable: false
    },
    {
        label: 'Email',
        key: 'email',
        width: 250,
        fullText: true,
        resizable: true
    },    
    {
        label: 'Subject',
        key: 'subject',
        width: 200,
        fullText: true,
        resizable: true
    },
    {
        label: 'Message',
        key: 'message',
        width: 500,
        fullText: true,
        resizable: true
    }
]