import React from 'react'
import { Link } from 'react-router-dom'
import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import hero1 from '../../images/slider/1.jpg'
// import hero2 from '../../images/slider/Ambience-2.jpg'
// import hero3 from '../../images/slider/Nature-1.jpg'
import hero1 from '../../images/slider/11.jpg'
import hero2 from '../../images/slider/12.jpg'
import hero3 from '../../images/slider/13.jpg'
import hero4 from '../../images/slider/14.jpg'
import hero5 from '../../images/slider/15.jpg'
import hero6 from '../../images/slider/16.jpg'
// import hero2 from '../../images/slider/slide-2.jpg'
// import hero3 from '../../images/slider/slide-3.jpg'


const sliderItems = [
    {
        sImg: hero1,
        title1: "Luxury Coastal ",
        title2: "Retreat"
    },
    {
        sImg: hero2,
        title1: "Rediscover",
        title2: " Yourself"
    },
    {
        sImg: hero3,
        title1: "",
        title2: "Rejuvenate"
    },
    {
        sImg: hero4,
        title1: "Luxury Coastal ",
        title2: "Retreat"
    },
    {
        sImg: hero5,
        title1: "Rediscover",
        title2: " Yourself"
    },
    {
        sImg: hero6,
        title1: "",
        title2: "Rejuvenate"
    },
]

const Hero = (props) => {
    return (
        <section className="wpo-hero-slider">
            <div className="swiper-container">
                <div className="swiper-wrapper">
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, A11y]}
                        spaceBetween={0}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        loop={true}
                        speed={1800}
                        parallax={true}
                        navigation
                    >
                        {sliderItems.map((item, slr) => (
                            <SwiperSlide key={slr}>
                                <div className="swiper-slide" style={{ backgroundImage: `url(${item.sImg})` }}>
                                    <div className="slide-inner slide-bg-image">
                                        <div className="container-fluid">
                                            <div className="slide-content">
                                                <div data-swiper-parallax="300" className="slide-title">
                                                <h2><span style={{color: "#fc4c4c"}}>{item.title1}</span>{item.title2}</h2>
                                                    {/* <h2>Find Your Perfect Place To Stay</h2> */}
                                                </div>
                                                <div className="clearfix"></div>
                                                <div data-swiper-parallax="500" className="slide-btns">
                                                    <Link to="/room" className="theme-btn">Book Now</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        ...
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default Hero;