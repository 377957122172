import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import { useParams } from 'react-router-dom'
import Navbar from '../../components/Navbar';
import Scrollbar from '../../components/scrollbar'
import Services from '../../api/service'
import Footer from '../../components/footer';
import Logo from '../../images/logo2.png'
import Newslatter from '../../components/Newslatter/Newslatter';
import ServiceSidebar from './sidebar';
import Categorys from './category';
import WhyChoose from './WhyChoose';
import bg from '../../images/room/roompage.jpg'

import { Helmet } from 'react-helmet-async';
import gl1 from '../../images/blog/2.jpg'
import gl2 from '../../images/blog/1.jpg'

const ServiceSinglePage = (props) => {
    const { id } = useParams()

    const serviceDetails = Services.find(item => item.id === id)


    return (
        <Fragment>
             <Helmet>
        <title>{serviceDetails.title} | Chira Meadows</title>
        <meta name="description" content="Discover a blend of relaxation, recreation, and fine dining at Chira Meadows. From Coastal Aroma’s exquisite cuisine and refreshing pools to a family-friendly Game Zone and rejuvenating spa services, Chira Meadows offers a unique experience for all. Enjoy modern amenities, seamless connectivity, and spaces designed for comfort, wellness, and memorable gatherings in the heart of Dapoli." />
        <meta name="keywords" content="Chira Meadows, Dapoli resort, fine dining, local and international cuisine, Coastal Aroma Restaurant, Game Zone, family entertainment, Convergence Hall, event space, wellness, Spa On Demand, relaxation, Exercise Space, fitness, free WiFi, complimentary breakfast, swimming pool, Jacuzzi, leisure, connectivity, rejuvenation, meetings, workshops, stay fit, dining, amenities" />
        <meta property="og:title" content={`${serviceDetails.title} | Chira Meadows`} />
        <meta property="og:description" content="Discover a blend of relaxation, recreation, and fine dining at Chira Meadows. From Coastal Aroma’s exquisite cuisine and refreshing pools to a family-friendly Game Zone and rejuvenating spa services, Chira Meadows offers a unique experience for all. Enjoy modern amenities, seamless connectivity, and spaces designed for comfort, wellness, and memorable gatherings in the heart of Dapoli." />
        <meta property="og:type" content="website" />
    </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={serviceDetails.title} pagesub={'Service'} bg={bg}/>
            <section className="service-single-section section-padding">
                <div className="container">
                    <div className="row wpo-blog-single-section">
                        <div className="col col-lg-8 col-12 post">
                            <div className="service-single-content">
                                <div className="service-single-img">
                                    <img src={serviceDetails.ssImg} alt="" />
                                </div>
                                <h2>{serviceDetails.title}</h2>
                                <p>{serviceDetails.subtitle}</p>
                                <p>{serviceDetails.description} </p>
                                <h4>{serviceDetails.titledesc}</h4>
                                <p>{serviceDetails.desc}</p>
                                <div className="gallery" style={{marginBottom:'1rem'}}>
                                    <div>
                                        {
                                            (serviceDetails.imgs1)?
                                            <img src={`/photo/${serviceDetails.imgs1[1]}`} alt="" />
                                            : 
                                            null
                                        }
                                        {/* <img src={`/photo/${serviceDetails.imgs[1]}`} alt="" /> */}
                                    </div>
                                    <div>
                                    {
                                            (serviceDetails.imgs1)?
                                            <img src={`/photo/${serviceDetails.imgs1[2]}`} alt="" />
                                            : 
                                            null
                                        }
                                        {/* <img src={`/photo/${serviceDetails.imgs[2]}`} alt="" /> */}
                                    </div>
                                </div>
                                <h4>{serviceDetails.titledesc1}</h4>
                                <p>{serviceDetails.desc1}</p>
                               
                                <div className="gallery">
                                    <div>
                                        {
                                            (serviceDetails.imgs)?
                                            <img src={`/photo/${serviceDetails.imgs[1]}`} alt="" />
                                            : 
                                            null
                                        }
                                        {/* <img src={`/photo/${serviceDetails.imgs[1]}`} alt="" /> */}
                                    </div>
                                    <div>
                                    {
                                            (serviceDetails.imgs)?
                                            <img src={`/photo/${serviceDetails.imgs[2]}`} alt="" />
                                            : 
                                            null
                                        }
                                        {/* <img src={`/photo/${serviceDetails.imgs[2]}`} alt="" /> */}
                                    </div>
                                </div>
                                <h2 style={{marginTop:'1rem'}}>{serviceDetails.titledesc2}</h2>
                                <h4>{serviceDetails.titledesc3}</h4>
                                <p>{serviceDetails.desc3}</p>
                                <WhyChoose id={id}/>
                                {/* <Categorys/> */}
                            </div>
                        </div>
                        <ServiceSidebar />
                    </div>
                </div>
            </section >
            {/* <Newslatter nClass={'section-bg'}/> */}
            <Footer />
            <Scrollbar />
        </Fragment >
    )
};
export default ServiceSinglePage;
