// import React from 'react';
import React, { useEffect } from 'react';
import AllRoute from '../router'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../sass/style.scss';
import ReactGA from 'react-ga';

const App = () => { 
  useEffect(() => {
    ReactGA.initialize('G-9BTM3HXLML');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="App" id='scrool'>
          <AllRoute/>
          <ToastContainer/>
    </div>
  );
}

export default App;
