import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo2.png'
import bg from '../../images/room/roompage.jpg'
import Testimonial from '../../components/Testimonial';
import Newslatter from '../../components/Newslatter/Newslatter';
import './ne.css'

const RoomPage =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={'Review'} pagesub={'Review'} bg={bg}/> 
            <div style={{marginTop:'5rem'}}></div>
            <Testimonial /> 
            {/* <Newslatter/> */}
            <div className='fhfh' style={{display:'flex',justifyContent:'space-evenly'}}>
            <iframe width="560" height="315" src='https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/vGhR-BsN07s?si=prd9LSSHSywaziLK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

export default RoomPage;
