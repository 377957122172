import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Offsets from '../../api/offsets'
import SectionTitle from '../SectionTitle';
import { useNavigate } from "react-router-dom";

const Offset = (props) => {
    const navigate = useNavigate();
    const [exploreAll, setExploreAll] = useState(false);
    function route(id) {
        // navigate(`/offsite`);
        navigate(`/offsite/${id}`);
        }
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const ClickHandler1 = () => {
        window.scrollTo(10, 0);
        setExploreAll(true);
    }
    const ClickHandler2 = () => {
        window.scrollTo(10, 0);
        setExploreAll(false);
    }
    return (
        <div className={`wpo-destination-area ${props.dClass}`} style={{padding:'60px 0'}}>
        {/* <div className={`wpo-destination-area ${props.dClass} section-padding`}> */}
            <div className="container">
                {
                    exploreAll?
                    <SectionTitle MainTitle={'Offsite'} subTitle={`Chira Meadows Ecoresort is special because it cares for nature and gives you a peaceful place to work and play.  Explore the Offsite Experiences at Chira Meadows EcoResort, where corporate outings transform into memorable retreats. 
                    <br/>
                    Chira Meadows is the perfect spot for teams to get closer, have fun and find new ideas in a calm and green setting. Come see how your team can have a great visit too.`} link={'/offsite'} buttonText={'Hide All'} click={ClickHandler2}/>
                    :
                    <SectionTitle MainTitle={'Offsite'} subTitle={`Chira Meadows Ecoresort is special because it cares for nature and gives you a peaceful place to work and play.  Explore the Offsite Experiences at Chira Meadows EcoResort, where corporate outings transform into memorable retreats. 
                    <br/>
                    Chira Meadows is the perfect spot for teams to get closer, have fun and find new ideas in a calm and green setting. Come see how your team can have a great visit too.`} link={'/offsite'} buttonText={'Explore All'} click={ClickHandler1}/>
                }
                {/* <SectionTitle MainTitle={'Our Most Populer Destination'} subTitle={'Places To Visit'} link={'/destination'}/> */}
                <div className="destination-wrap">
                    <div className="row">
                    {exploreAll
                            ? Offsets.map((destination, ditem) => (
                                <div className="col-lg-4 col-md-6 col-12" key={ditem} style={{ cursor: 'pointer' }} onClick={() => route(destination.id)}>
                                    <div className="destination-item" style={{ minHeight: '65vh' }}>
                                        <div className="destination-img">
                                            <img src={destination.dimg1} alt="" />
                                        </div>
                                        <div className="destination-content" style={{padding:'20px'}}>
                                            <div>
                                                <i class="fi fi-rr-calendar-clock"></i> <span className="sub">{destination.subTitle}</span>
                                            </div>
                                            <h2><Link onClick={ClickHandler} to={`/offsite`}>{destination.title}</Link></h2>
                                        </div>
                                    </div>
                                </div>
                            ))
                            :
                        Offsets.slice(0, 3).map((destination, ditem) => (
                            <div className="col-lg-4 col-md-6 col-12" key={ditem} style={{cursor:'pointer'}} onClick={() => route(destination.id)}>
                                <div className="destination-item" style={{minHeight:'65vh'}}>
                                    <div className="destination-img">
                                        <img src={destination.dimg1} alt=""/>
                                    </div>
                                    <div className="destination-content" style={{padding:'20px'}}>
                                        <div>    
                                            <i class="fi fi-rr-calendar-clock"></i> <span className="sub">{destination.subTitle}</span>
                                        </div>
                                        <h2><Link onClick={ClickHandler} to={`/offsite`}>{destination.title}</Link></h2>
                                        {/* <div className="destination-bottom">
                                            <p>${destination.price} Per Night</p>
                                            <div className="destination-bottom-right">
                                                <ul>
                                                    <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                    <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                    <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                    <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                    <li><span><i className="fa fa-star" aria-hidden="true"></i></span></li>
                                                </ul>
                                                <small>4.5</small>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    )

}

export default Offset;
