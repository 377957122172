import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo2.png'
import bg from '../../images/destination/roompage.jpg'
import Newslatter from '../../components/Newslatter/Newslatter';
import SubscribePageSec from '../../components/SubscribePageSec/SubscribePageSec';


const ContactPage =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            {/* <PageTitle pageTitle={'Booking'} pagesub={'Booking'} bg={bg}/>  */}
            <SubscribePageSec/> 
            {/* <Newslatter nClass={'section-bg'}/> */}
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

export default ContactPage;

