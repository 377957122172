import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import test1 from '../../images/testimonial/1.png'
import test2 from '../../images/testimonial/3.jpg'
import test3 from '../../images/testimonial/1.png'
import test4 from '../../images/testimonial/4.jpg'
import ytest1 from '../../images/testimonial/img-1.jpg'
import ytest2 from '../../images/testimonial/img-2.jpg'
import ytest3 from '../../images/testimonial/img-3.jpg'

import VideoModal from '../ModalVideo'




const Testimonials = [
    {
        tstImg: test1,
        ytstImg: 'https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W',
        name: 'Sejal',
        title: 'Photographer',
        descriptoion: 'Nice place to stay. Beaches are 10 km away from resort.but place is value for money if you want to spent quality time with your family and friends.i recommend this place for nature lovers.',
    },
    {
        tstImg: test2,
        ytstImg: 'https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W',
        name: 'Divyashree Bhat',
        title: 'CEO Of Golden Bravo',
        descriptoion: 'Lovely place. We stayed for two nights. The cottages are large and comfortable. Special mention of the Konkan fare that was served. Amazing solkadhi and bhakti with usual. The staff is courteous and friendly. Would highly recommend this place.',
    },
    {
        tstImg: test4,
        ytstImg: 'https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W',
        name: 'Sameer Kulkarni',
        title: 'CEO Of Bexima',
        descriptoion: 'Beautiful classic eco friendly.. very beautiful if you want to relax and have fun. Good sports room. And very elegant rooms cottages.. loved it. great staff.. alfred and upendra very helpful gentleman.. best for family get together',
    },
    {
        tstImg: test1,
        ytstImg: 'https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W',
        name: 'One Wild Soul',
        title: 'CEO Of Bexima',
        descriptoion: 'Very beautiful place, well maintained staff & the ambience is really fantastic. The owner Mr.Siddhart Jagdale is very concerned about the hospitality & comfort of guests.',
    },
    {
        tstImg: test2,
        ytstImg: 'https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W',
        name: 'Abhay Rajguru',
        title: 'CEO Of Bexima',
        descriptoion: 'Excellent ambiance good hospitality nice staff very nicely equipped with all modern indoor gaming accessories. Overall very friendly and excellent experience',
    },
    {
        tstImg: test4,
        ytstImg: 'https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W',
        name: 'Manish Singh',
        title: 'CEO Of Bexima',
        descriptoion: `Loved every minute of our 2 day stay here. The hosts are such warm and wonderful people. It was our wedding anniversary, and they planned an unexpected and amazing surprise for us.

        It was a comfortable stay. The food is amazing- please have fish here. The support staff is very cordial.
        We would definitely be back here. Thank you for the amazing two days.`,
    },
    {
        tstImg: test3,
        ytstImg: 'https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W',
        name: 'Pankaj Jain',
        title: 'CEO Of Bexima',
        descriptoion: `Pleasant, Serene and in the lap of mother nature I enjoyed stay for 8 days in Chira Meadows Ecoresort, Dapoli. As the name suggest the Ecoresort is full of greenery and gives the experience of blissful natural vibes. The Ecoresort is having all modern facilities for playing games, convergance hall etc.The rooms are clean, The food quality is very high and the restaurant staff is very courteous. The Resort Manager Mr. Rajendra Jain is very kind and well behaved person who is ready to help for everything within no time. It was a pleasant and blissful stay.

        Experience the bliss of mother nature`,
    },
    {
        tstImg: test2,
        ytstImg: 'https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W',
        name: 'Deepika Gahlawat',
        title: 'CEO Of Bexima',
        descriptoion: `It's an awesome place to spend some quality time with friends and family. The service is first class and the food is great. It's worth spending the money on this resort. Must visit place.`,
    },
    {
        tstImg: test4,
        ytstImg: 'https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W',
        name: 'Muku Sipani',
        title: 'CEO Of Bexima',
        descriptoion: `What an experience !  Wonderful and cooperative  staff, clean and huge rooms with balcony views, order based tasty food , serene , quiet and just beautiful  overall .. don't miss an opportunity to be here with friends or solo.. u will love this one outing`,
    },
]



const Testimonial = () => {

    var settings = {
        dots: false,
        arrows: true,
        speed: 1200,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
    };

    return (
        // <div className="wpo-testimonial-area" style={{paddingTop:'120px'}}>
        <div className="wpo-testimonial-area">
            <div className="container">
                <div className="wpo-testimonial-wrap">
                    <div className="testimonial-slider">
                        <Slider {...settings}>
                            {Testimonials.map((tstml, tsm) => (
                                <div className="wpo-testimonial-item" key={tsm}>
                                    <div className="wpo-testimonial-img">
                                    {/* <iframe width="410" height="410" src={tstml.ytstImg} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                                        <img src={tstml.tstImg} alt="" />
                                    </div>
                                    <div className="wpo-testimonial-content">
                                        <p>{tstml.descriptoion}</p>
                                        <h2>{tstml.name}</h2>
                                        {/* <span>Previous Client</span> */}
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
            {/* <div class="post  format-video">
            <div className="entry-media video-holder">
                    <img src={test1} alt=""/>
                     <VideoModal/>
            </div>
            </div> */}
        </div>
    )
}

export default Testimonial;