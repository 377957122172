// import data from './data.json';

// export default () => {
//   return data;
// }


// import React, {useEffect} from 'react';
// import axios from 'axios';
// import data from './data.json';
// const updateDataWithPrices = async () => {
//   try {
//     const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/price`);
//     const updatedData = data.map(item => {
//       const priceItem = response.data.data.find(apiItem => apiItem.id === item.id);
//       if (priceItem) {
//         return {
//           ...item,
//           price: priceItem.price 
//         };
//       } else {
//         return item;
//       }
//     });
//     data = updatedData;
//     console.log(data)
//     console.log(updatedData)
//   } catch (error) {
//     console.error('Error fetching data:', error.message);
//   }
// };

// updateDataWithPrices()

// export default () => {
//   updateDataWithPrices()
//   return data;
// }

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import data from './data.json';

const fetchDataAndUpdatePrices = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/price`);
    const updatedData = data.map(item => {
      const priceItem = response.data.data.find(apiItem => apiItem.id === item.id);
      if (priceItem) {
        return {
          ...item,
          price: priceItem.price 
        };
      } else {
        return item;
      }
    });
    return updatedData;
  } catch (error) {
    console.error('Error fetching data:', error.message);
    return data; 
  }
};

const MyComponent = () => {
  const [updatedData, setUpdatedData] = useState(data);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await fetchDataAndUpdatePrices();
      setUpdatedData(newData);
    };
    fetchData(); 
  }, []);

  return updatedData;
};

export default MyComponent;



// const Api = (props) => {
  // return (updateDataWithPrices)
  // }
  // export default data;
// updatedData = data;
// return []; 
// export default updateDataWithPrices().catch(error => {
  // console.error('Error updating data with prices:', error.message);
  // return data; 
  // });
  // return updatedData;