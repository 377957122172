import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo2.png'
import About2 from '../../components/about2/about2';
import Newslatter from '../../components/Newslatter/Newslatter';
import Destination from '../../components/Destination';
import FunFact from '../../components/FunFact';
import Rooms from '../../components/Rooms/Rooms';
import ServiceSection2 from '../../components/ServiceSection2/ServiceSection2';
import PricingSection from '../../components/PricingSection';
import Testimonial from '../../components/Testimonial';
import { Helmet } from 'react-helmet-async';

const AboutPage =() => {
    return(
        <Fragment>
            <Helmet>
                <title>About Us | Your Website Name</title>
                <meta name="description" content="Learn more about us and what makes us unique on this page." />
                <meta name="keywords" content="About us, Company, Services, Our Story" />
                <meta property="og:title" content="About Us | Your Website Name" />
                <meta property="og:description" content="Discover more about our story, values, and team." />
                <meta property="og:type" content="website" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={'About Us'} pagesub={'About'}/> 
            <About2/>
            <Destination/> 
            <FunFact fClass={'section-padding'}/> 
            <Rooms/> 
            <ServiceSection2/> 
            <PricingSection/> 
            <Testimonial/> 
            <Newslatter nClass={'section-bg'}/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

export default AboutPage;
