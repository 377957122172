import React from 'react';
import ContactForm from '../ContactFrom'


const ContactpageSec = () => {
    const callPhoneNumber = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };

    // Function to compose an email
    const composeEmail = (emailAddress) => {
        window.location.href = `mailto:${emailAddress}`;
    };
    return (
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item" style={{ height: '100%' }}>
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-maps-and-flags"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Address</h2>
                                            <p>Chira Meadows Ecoresort,
                                                Om Sai Nagar, Dapoli, Dist Ratnagiri,
                                                Jalgaon, Maharashtra 415712</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item" style={{ height: '100%' }}>
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Email Us</h2>
                                            <p style={{ textAlign: 'center' }}><a style={{ textDecoration: 'none' }} href="#" onClick={() => composeEmail('chirameadows@gmail.com')}>chirameadows@gmail.com</a></p>
                                            {/* <p>helloyou@gmail.com</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item" style={{ height: '100%' }}>
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Call Now</h2>
                                            <p style={{ textAlign: 'center' }}><a href="#" style={{ textDecoration: 'none' }} onClick={() => callPhoneNumber('+91-7030440505')}>+91-7030440505</a></p>
                                            <p style={{ textAlign: 'center' }}><a href="#" style={{ textDecoration: 'none' }} onClick={() => callPhoneNumber('+91-9423010005')}>+91-9423010005</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wpo-contact-title">
                            <h2>Have Any Question?</h2>
                            <p>You can contact us, right here – right now. Use this below contact details to reach out to us. Usually we respond in 1 business day.</p>
                        </div>
                        <div className="wpo-contact-form-area">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.9147703055!2d-74.11976314309273!3d40.69740344223377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew+York%2C+NY%2C+USA!5e0!3m2!1sen!2sbd!4v1547528325671"></iframe>
                     */}

                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7600.270088806465!2d73.200318!3d17.738275!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be9c3cb5dad3b7d%3A0x8e00cf8b5f89563d!2sChira%20Meadows%20Ecoresort%20%26%20Wellness%20Spa%2C%20Dapoli!5e0!3m2!1sen!2sin!4v1701879073099!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </section>
        </section>
    )

}

export default ContactpageSec;
