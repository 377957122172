import React from 'react'
import offsets from '../../api/offsets'
import { Link } from 'react-router-dom'
import ins1 from '../../images/instragram/1.jpg'
import ins2 from '../../images/instragram/2.jpg'
import ins3 from '../../images/instragram/3.jpg'
import ins4 from '../../images/instragram/4.jpg'
import ins5 from '../../images/instragram/5.jpg'
import ins6 from '../../images/instragram/6.jpg'

const DestinationSidebar = (props) => {

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
console.log(props.details)
    return (
        <div className="col-lg-4 col-md-8">
            <div className="wpo-single-sidebar">
                <div className="wpo-service-widget widget">
                    <h2>Videos</h2>
                    <ul>
                        {props.details.utube?.length > 0 && (props.details.utube).map((destination, Sitem) => (
                            <div key={Sitem} style={{marginTop:'50px'}}>
                            <iframe width="320" height="240" src={`https://www.youtube.com/embed/${destination}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            {/* <iframe width="320" height="240" src='https://www.youtube.com/embed/CU9eJF5V7-M?si=5-_2bPghpZQPV2l_' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                            {/* <iframe width="320" height="240" src='https://www.youtube.com/embed/EoAriHbwpqI?si=oCWfmCk4YoX1-i3W' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                            </div>
                        ))}
                    </ul>
                </div>
                {/* <div className="wpo-service-widget widget">
                    <h2>All Destination</h2>
                    <ul>
                        {offsets.slice(0, 11).map((destination, Sitem) => (
                            <li key={Sitem}><Link onClick={ClickHandler} to={`/offsite/${destination.id}`}>{destination.title}</Link></li>
                        ))}
                    </ul>
                </div> */}
            </div>
        </div>

    )
}

export default DestinationSidebar;