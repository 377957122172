import React from 'react'

const SectionTitleS2 = (props) => {
    return(
        <div className="wpo-section-title-s2">
            <h2>{props.MainTitle}</h2>
            {
                            (props.subTitle)?(<p style={{width: "fit-content"}}>{props.subTitle}</p>)
                            :
                            (null)}
                            {/* (<p>It is a long established fact that a reader will be distracted by the readable
                            content of a page.</p>)} */}
        </div>

    )
}

export default SectionTitleS2;