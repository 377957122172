// import dimg1 from `${process.env.REACT_APP_AWS}offsite-logo/flipkart.png`
// import dimg2 from `${process.env.REACT_APP_AWS}offsite-logo/stp.jpg`
// import dimg3 from '../images/destination1/3.jpg'
// import dimg4 from '../images/destination1/4.jpg'
// import dimg5 from '../images/destination/5.jpg'
// import dimg6 from '../images/destination/6.jpeg'
// import dimg7 from '../images/destination/7.jpg'
// import dimg8 from '../images/destination/8.jpg'
// import dimg9 from '../images/destination/9.jpg'
// import dimg10 from '../images/destination/10.jpg'
// import dimg11 from '../images/destination/11.jpg'

// import dSimg1 from '../images/destination-single/1.jpg'
// import dSimg2 from '../images/destination-single/2 (1).jpg'
// import dSimg3 from '../images/destination-single/3.jpg'
// import dSimg4 from '../images/destination-single/4.jpg'
// import dSimg5 from '../images/destination-single/5.jpg'
// import dSimg6 from '../images/destination-single/6.jpeg'
// import dSimg7 from '../images/destination-single/7.jpg'
// import dSimg8 from '../images/destination-single/8.jpg'
// import dSimg9 from '../images/destination-single/9.jpg'
// import dSimg10 from '../images/destination-single/10.jpg'
// import dSimg11 from '../images/destination-single/11.jpg'



const Destinations = [
    {
        id: '1',
        title:`FLIPKART`,
        subTitle:'Wed, 13th December 2023',
        dimg1:`${process.env.REACT_APP_AWS}offsite-logo/flipkart.png`,
        imgs:["offsite-images/Flipkart/1.png","offsite-images/Flipkart/2.png","offsite-images/Flipkart/3.png","offsite-images/Flipkart/4.png","offsite-images/Flipkart/5.png","offsite-images/Flipkart/6.png","offsite-images/Flipkart/7.png","offsite-images/Flipkart/8.png","offsite-images/Flipkart/9.png"],
        utube:[],
        // dSimg:dSimg1,
        // para1:'Anjarle is one of the secluded beaches in Dapoli. Anjarle has a clean beach with a cover of white sand and is surrounded by palms and trees.  Anjarle beach is about two Kms long and is perched in one of the most picturesque locations of Anjarle.',
        // para2:'There are some other beaches as well which are at a driving distance from our resort ,like : Ladghar , Murud , Kolthare'
    },
    {
        id: '2',
        title:'SCIENCE AND TECHNOLOGY PARK',
        subTitle:'Tue, 19th December 2023',
        dimg1:`${process.env.REACT_APP_AWS}offsite-logo/stp.jpg`,
        imgs:["offsite-images/STP/1.png","offsite-images/STP/2.png","offsite-images/STP/3.png","offsite-images/STP/4.png","offsite-images/STP/5.png","offsite-images/STP/6.png","offsite-images/STP/7.png","offsite-images/STP/8.png"],
        utube:["CU9eJF5V7-M?si=Uzr6MfxCbZQ2voMo","33j6MfD1_RU?si=G2gp_OjsurjNhO7s","CXIyklVNzMg?si=ooS697jQCiB01ebQ"],
        // dSimg:dSimg2,
        // para1:'Harnai port is the main fishing village. Every evening hundreds of fishing boats come back after fishing and gather to auction their catch. The fish auction is the essential trade step between fish landings and the trade.Auction of fishes in the evening from 4:00pm to 7:00pm.Fishing is the main source of business of people of Harnai.Followed by the Mango festival.',
        // para2:'You could vist the Kanakdurg fort with a lighthouse offering splendid views of the ocean Suvarnadurg fort and the fishing village. You can also visit the historic Suvarnadurg fort from here and enjoy a boat ride too!'
    },
    // {
        // id: '3',
        // title:'Google',
        // subTitle:'Mon, 22th January 2024',
        // dimg1:dimg3,
        // price:'280',
        // dSimg:dSimg3,
        // para1:'One of the two Parashuram temples in Konkan, the other being the one in Chiplun, Shree Bhargavram temple is located in Ade approximately 10 kilometers from Anjarle. Shree Bhargavram temple complex with five temples each of which faces east is located between Kelshi and Ade . Along with the main temple of Shree Bhargavram, this complex also houses the temples of Shiva, Renuka, Hanuman, Ganesh, Vetal and Shani. ',
        // para2:'The temple was built by initiative from Swami ParamhansBrahmendra. He was the Guru of SiddiYakutkhan of Janjira, KanhojiAngre of Kolaba, Peshwa of Pune, ChatrapatiSahu Maharaj, Tararani of Kolhapur'
    // },
 
    // {
    //     id: '5',
    //     title:'SUVARNADURG',
    //     subTitle:'A FORT',
    //     dimg1:dimg5,
    //     dSimg:dSimg5,
    //     price:'180',
    //     para1:'Suvarnadurg  is a fort that is located  near Harnai in Konkan, along the West Coast of India. The fort also includes another small land fort called the Kanakadurga at the base of headland of Harnai port on the coast. ',
    //     para2:'Building of the fort is credited to Shivaji Maharaj, founder of the Maratha Empire, in 1660'
    // },
    // {
    //     id: '6',
    //     title:'PANHALAKAJI CAVES',
    //     subTitle:'CAVES',
    //     dimg1:dimg6,
    //     dSimg:dSimg6,
    //     price:'400',
    //     para1:'Panhalakaji Caves are situated in the Ratnagiri district of Maharashtra state, about 160km south of Mumbai. This cave complex has around 30 Buddhist Caves. The Hinayana sect began carving caves in 3rd century AD, beginning with the stupa in the current Cave 5.',
    //     para2:'The caves have inscriptions in Brahmi and Devanagari script. In the 10-11th century AD another Buddhist group, a Vajrayana sect, established cave 10 with their deities, Akshobhya and Mahachandaroshana; and strengthened their practice in that region. Shiva and Ganpatya worshiping started at the site during Silahararule.There are close to 29 caves and many sculptures around. It is the best place for a history buff of adventure enthusiast.'
    // },
    // {
    //     id: '7',
    //     title:'MURUD VILLAGE & BEACH',
    //     subTitle:'BEACH',
    //     dimg1:dimg7,
    //     dSimg:dSimg7,
    //     price:'400',
    //     para1:'Murud Beach in Raigad district of Maharashtra is one of the most prominent tourist attractions of the western Indian region. It is famous for its beautiful sandy coastline adorned with majestic forts and bordered by columns of coconut and palm trees. ',
    //     para2:' The calm and serene atmosphere with splendid scenic beauty makes Murud a great place for a family holiday. With multiple options for accommodation, the beachside locale is the place to relax and enjoy a fun-filled holiday.'
    // },
    // {
    //     id: '8',
    //     title:'KESHAVRAJ TEMPLE',
    //     subTitle:'TEMPLE',
    //     dimg1:dimg8,
    //     dSimg:dSimg8,
    //     price:'400',
    //     para1:'Shree Keshavraj Temple resides in a beautiful village known as Asud. The deity worshipped in this temple is Lord Vishnu.',
    //     para2:'Tourists who want to visit this place should take the Harnai road from Dapoli. There is one more famous temple near this place known as Shree Vyagreshwar temple.'
    // },
    // {
    //     id: '9',
    //     title:'TAMAS TEERTH, LADGHAR',
    //     subTitle:'BEACH',
    //     dimg1:dimg9,
    //     dSimg:dSimg9,
    //     price:'400',
    //     para1:'Tamas Tirth is named due to red sand or red stone at the shore.. (Tamas is red in marathi), tirth is holy water.',
    //     para2:'View from 21ft Parshuram Statue is truly amazing and a must go place if you are in Dapoli. Roads are narrow so drive carefully.'
    // },
    // {
    //     id: '10',
    //     title:'CHANDRIKA DEVI TEMPLE',
    //     subTitle:'CAVES',
    //     dimg1:dimg10,
    //     dSimg:dSimg10,
    //     price:'400',
    //     para1:'The temple of Chandikadevi which is situated in Dabhol creek. It is a very unique, popular and ancient temple. The idol of the Goddess made in a single stone is placed  in a natural cave in the mountain. Electricity is not used in the area of the temple. ',
    //     para2:'The sight of the idol in the soft lights of the oil lanterns is mesmerising. This ancient Chandikadevi temple which is considered to be more mysterious than other temples is sure to be a unique and memorable experience.'
    // },
    // {
    //     id: '11',
    //     title:'PALGADH FORT',
    //     subTitle:'FORT',
    //     dimg1:dimg11,
    //     dSimg:dSimg11,
    //     price:'400',
    //     para1:'It is one of the rear mid hill forts. The fort offers beautiful view of Sahyadri ranges and its offshoots descending towards west. It forms a boundary between Dapoli taluka in the west and Khed Taluka in the east. ',
    //     para2:'There is dense forest on the western side of the hill whereas beautifully carved rice terraces in the east. The way at the entrance to the fort is carved out of rock. There are some remnants of the old buildings and two canons. There is one well which has no water in it.'
    // },
]
export default Destinations;