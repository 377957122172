import React from 'react';
import { Link } from 'react-router-dom'
import './somestyle.css';

const BlogSingle = (props) => {
    const composeEmail = (emailAddress) => {
        window.location.href = `mailto:${emailAddress}`;
    };
    return (
        <section className="wpo-blog-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                        <div className="wpo-blog-content">
                            <div className="post format-standard-image">
                                <h2>Privacy Policy for Chira Meadows Eco Resort</h2>
                                <p>Welcome to Chira Meadows Eco Resort's website. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, disclose and safeguard your information when you visit our website.</p>
                                {/* <p dangerouslySetInnerHTML={{ __html: BlogDetails.desc1 }} />
                                <blockquote>
                                    Discover Dapoli's charm, where every corner unfolds new adventures and unforgettable experiences. Dive into a world of endless possibilities – explore, savor, and create cherished memories.
                                </blockquote> */}
                                <h3>
                                    1. Information We Collect
                                </h3>
                                <p>We may collect the following types of information from you:</p>
                                <ul className='blogul1' dangerouslySetInnerHTML={{
                                    __html: `<strong>Personal Identification Information:</strong> Name, email address, phone number and any other information you provide when making a reservation or contacting us.
                                               <br/>  <br/><strong>Usage Data:</strong> Information about how you access and use our website, such as your IP address, browser type and browsing activity.
                                               <br/> <br/><strong>Cookies:</strong> We may use cookies to enhance your browsing experience and personalise our services. You can choose to disable cookies in your browser settings, but this may affect your use of our website.` }} />
                                <h3>
                                    2. How We Use Your Information
                                </h3>
                                <p>We use your information for the following purposes:</p>
                                <ul className='blogul1' dangerouslySetInnerHTML={{
                                    __html: `<li>To process and manage your reservations and inquiries.</li>
<li>To communicate with you about our services, offers and promotions.</li>
<li>To improve our website and customer service.</li>
<li>To comply with legal obligations and protect our rights.</li>
` }} />
                                <h3>
                                    3. Sharing Your Information
                                </h3>
                                <p>We do not sell or rent your personal information to third parties. We may share your information with trusted partners who assist us in operating our website, conducting our business or providing services to you, as long as those parties agree to keep this information confidential. We may also disclose your information when required by law or to protect our rights and property.</p>
                                <h3>
                                    4. Security of Your Information
                                </h3>
                                <p>We take reasonable measures to protect your personal information from unauthorised access, disclosure or destruction. However, no internet transmission or electronic storage is completely secure and we cannot guarantee absolute security.</p>
                                <h3>
                                    5. Changes to This Privacy Policy
                                </h3>
                                <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated policy on our website.</p>
                                <h3>
                                    6. Contact Us
                                </h3>
                                <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at: <a style={{ textDecoration: 'none' }} href="#" onClick={() => composeEmail('chirameadows@gmail.com')}><strong>chirameadows@gmail.com</strong></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default BlogSingle;
