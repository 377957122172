import React from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Services from '../../api/service'


const WhyChoose = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
     // Assuming your current service ID is passed through props
    const currentServiceId = props.id;
    // const currentServiceId = props.currentServiceId;
    // Find the index of the current service in the Services array
    const currentIndex = Services.findIndex((service) => service.id === currentServiceId);
    const nextThreeServices =
    currentIndex + 3 < Services.length
        ? Services.slice(currentIndex + 1, currentIndex + 4)
        : [...Services.slice(currentIndex + 1), ...Services.slice(0, 3 - (Services.length - currentIndex - 1))];
    return(
        <div className="why-choose-section">
            <h3>Other Services</h3>
            {/* <h3>Why Choose This Service</h3> */}
            <div className="feature-grids clearfix">
                {nextThreeServices.map((service, sitem) => (
                        <Link onClick={ClickHandler} to={`/service-single/${service.id}`}>
                    <div className="grid" key={sitem} style={{minHeight:'400px'}}>

                        <div className="icon">
                             <i className={`fi ${service.fIcon}`}></i>
                        </div>
                        <div className="hover-icon">
                             <i className={`fi ${service.fIcon}`}></i>
                        </div>
                        <h3><>{service.title}</></h3>
                        <p>{service.description}</p>
                    </div>
                        </Link>
                ))}
            </div>
        </div>
    )
}

export default WhyChoose;