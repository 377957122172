import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo2.png'
import bg from '../../images/room/roompage.jpg'
import Rooms2 from '../../components/Rooms2/Rooms2';
import Newslatter from '../../components/Newslatter/Newslatter';
import { Helmet } from 'react-helmet-async';

const RoomPage =() => {
    return(
        <Fragment> 
            <Helmet>
        <title>Rooms | Chira Meadows</title>
        <meta name="description" content="Experience ultimate comfort in our spacious luxury rooms at Chira Meadows. Each room features a neat and clean bathroom and a private verandah, perfect for relaxing and enjoying the stunning views of nature. Unwind in a tranquil atmosphere designed for your rejuvenation." />
        <meta name="keywords" content="luxury rooms, spacious accommodations, clean bathrooms, private verandah, comfort, relaxing stay, Chira Meadows rooms, nature views" />
        <meta property="og:title" content="Rooms | Chira Meadows" />
        <meta property="og:description" content="Experience ultimate comfort in our spacious luxury rooms at Chira Meadows. Each room features a neat and clean bathroom and a private verandah, perfect for relaxing and enjoying the stunning views of nature. Unwind in a tranquil atmosphere designed for your rejuvenation." />
        <meta property="og:type" content="website" />
    </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={'Rooms'} pagesub={'Rooms'} bg={bg}/> 
            <Rooms2/>
            {/* <Newslatter/> */}
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

export default RoomPage;
