import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo2.png'
import bg from '../../images/destination/roompage.jpg'
import Newslatter from '../../components/Newslatter/Newslatter';
import ContactpageSec from '../../components/ContactpageSec/ContactpageSec';
import { Helmet } from 'react-helmet-async';


const ContactPage =() => {
    return(
        <Fragment>
                <Helmet>
        <title>Contact | Chira Meadows</title>
        <meta name="description" content="Get in touch with us at Chira Meadows! Whether you have questions about our services, wish to make a reservation, or need assistance, our friendly team is here to help. Reach out today and start planning your perfect getaway!" />
        <meta name="keywords" content="contact Chira Meadows, inquiries, reservations, eco-resort support, customer service, Dapoli resort contact, get in touch" />
        <meta property="og:title" content="Contact | Chira Meadows" />
        <meta property="og:description" content="Get in touch with us at Chira Meadows! Whether you have questions about our services, wish to make a reservation, or need assistance, our friendly team is here to help. Reach out today and start planning your perfect getaway!" />
        <meta property="og:type" content="website" />
    </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={'Contact Us'} pagesub={'Contact'} bg={bg}/> 
            <ContactpageSec/> 
            {/* <Newslatter nClass={'section-bg'}/> */}
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

export default ContactPage;

