import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Logo from '../../images/logo2.png'
import PrivacyDetails from '../../components/PrivacyDetails'
import Scrollbar from '../../components/scrollbar'
import Footer from '../../components/footer';

const BlogDetailsFull =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={'Privacy Policy'} pagesub={'Home'}/> 
            <PrivacyDetails blLeft={'d-none'} blRight={'col-lg-10 offset-lg-1'}/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default BlogDetailsFull;